export enum PatientRoutes {
    ACCOUNT = '/account',
    ASSESSMENTS = '/assessments',
    DASHBOARD = '/',
    HELP = '/help',
    RESOURCES = '/resources',
    FIRST_TIME_ACCOUNT_SETUP = '/account-setup',
    IDENTITY_CHECK = '/identity',
    ASSESSMENT = '/form',
    ASSESSMENT_RESULTS = '/assessment-results',
    CONSENT = '/consent',
}

export type PatientRoute = `${PatientRoutes}`;

export enum ProviderRoutes {
    ACCOUNT = '/account',
    ASSESSMENT = '/form',
    ASSESSMENT_RESULTS = '/assessment-results',
    DASHBOARD = '/',
    FACILITY_LIBRARY = '/facility-library',
    HELP = '/help',
    PATIENT_ACCOUNTS = '/patient-accounts',
    PATIENT_REGISTRATION = '/patient-accounts/registration',
    PRINTABLE_ASSESSMENTS = '/printable-assessments',
    CORE_MEASURES_WORKLIST = '/core-measures-worklist',
    CORE_MEASURES_FACILITY_INFO = '/core-measures-facility-info',
    CORE_MEASURES_FACILITY_INFO_POPULATION_ENTRY = '/core-measures-facility-info-population-entry/:summaryId/:facilityId/:year/:month/:isFormFacility'
}

export type ProviderRoute = `${ProviderRoutes}`;

export enum ExecutiveRoutes {
    ACCOUNT = '/account',
    DASHBOARD = '/',
    HELP = '/help',
    FACILITY_LIBRARY = '/facility-library',
    FACILITY_USER_ACCOUNTS = '/facility-user-accounts',
    FACILITY_USER_REGISTRATION = '/facility-user-accounts/registration',
    FACILITY_USER_EDIT = '/facility-user-accounts/edit',
    PATIENT_ACCOUNTS = '/patient-accounts',
    PATIENT_REGISTRATION = '/patient-accounts/registration',
    PRINTABLE_ASSESSMENTS = '/printable-assessments',
    REPORTS = '/reports',
    ASSESSMENT = '/form',
    ASSESSMENT_RESULTS = '/assessment-results',
    CORE_MEASURES_WORKLIST = '/core-measures-worklist',
    CORE_MEASURES_FACILITY_INFO = '/core-measures-facility-info',
    CORE_MEASURES_FACILITY_INFO_POPULATION_ENTRY = '/core-measures-facility-info-population-entry/:summaryId/:facilityId/:year/:month/:isFormFacility'
}

export type ExecutiveRoute = `${ExecutiveRoutes}`;

export enum AdminRoutes {
    ACCOUNT = '/account',
    DASHBOARD = '/',
    HELP = '/help',
    FACILITY_LIBRARY = '/facility-library',
    FACILITY_USER_ACCOUNTS = '/facility-user-accounts',
    FACILITY_USER_REGISTRATION = '/facility-user-accounts/registration',
    FACILITY_USER_EDIT = '/facility-user-accounts/edit',
    PATIENT_ACCOUNTS = '/patient-accounts',
    PATIENT_REGISTRATION = '/patient-accounts/registration',
    REPORTS = '/reports',
    RESOURCES = '/resources',
    ASSESSMENT = '/form',
    ASSESSMENT_RESULTS = '/assessment-results',
    CORE_MEASURES_WORKLIST = '/core-measures-worklist',
    CORE_MEASURES_FACILITY_INFO = '/core-measures-facility-info',
    CORE_MEASURES_FACILITY_INFO_POPULATION_ENTRY = '/core-measures-facility-info-population-entry/:summaryId/:facilityId/:year/:month/:isFormFacility'
}

export type AdminRoute = `${AdminRoutes}`

export enum SuperAdminRoutes {
    ACCOUNT = '/account',
    ASSESSMENT_EDITOR = '/assessment-editor',
    DASHBOARD = '/',
    HELP = '/help',
    FACILITY_LIBRARY = '/facility-library',
    FACILITY_USER_ACCOUNTS = '/facility-user-accounts',
    FACILITY_USER_REGISTRATION = '/facility-user-accounts/registration',
    FACILITY_USER_EDIT = '/facility-user-accounts/edit',
    PATIENT_ACCOUNTS = '/patient-accounts',
    PATIENT_REGISTRATION = '/patient-accounts/registration',
    REPORTS = '/reports',
    RESOURCES = '/resources',
    ASSESSMENT = '/form',
    ASSESSMENT_RESULTS = '/assessment-results',
    CORE_MEASURES_WORKLIST = '/core-measures-worklist',
    CORE_MEASURES_FACILITY_INFO = '/core-measures-facility-info',
    CORE_MEASURES_FACILITY_INFO_POPULATION_ENTRY = '/core-measures-facility-info-population-entry/:summaryId/:facilityId/:year/:month/:isFormFacility'
}

export type SuperAdminRoute = `${SuperAdminRoutes}`;

export enum AnonymousRoutes {
    LOGIN = '/login',
    REGISTER_BY_PIN = '/register-by-pin',
    ASSESSMENT = '/assessment/:assessmentLinkHash',
    ACCOUNT_DELETE_CONFIRM = '/account-delete-confirm/:accountDeleteLinkHash'
}

export type AnonymousRoute = `${AnonymousRoutes}`;

export enum AllRoutes {
    ACCOUNT = '/account',
    ASSESSMENT = '/form',
    ASSESSMENT_EDITOR = '/assessment-editor',
    ASSESSMENT_RESULTS = '/assessment-results',
    DASHBOARD = '/',
    DOWNLOAD = '/download',
    FACILITY_LIBRARY = '/facility-library',
    FACILITY_USER_ACCOUNTS = '/facility-user-accounts',
    FACILITY_USER_EDIT = '/facility-user-accounts/edit',
    FACILITY_USER_REGISTRATION = '/facility-user-accounts/registration',
    GROUP_THERAPY_START_SESSION = '/group-therapy-session/start',
    GROUP_THERAPY_BASE = '/group-therapy-session',
    GROUP_THERAPY_PARTICIPANTS = '/group-therapy-session/:groupId/participants',
    GROUP_THERAPY_ASSESSMENTS = '/group-therapy-session/:groupId/assessments/:patientId',
    GROUP_THERAPY_EXIT_SESSION = '/group-therapy-session/:groupId/exit-session',
    HELP = '/help',
    HELP_DOWNLOAD = '/help/download',
    ATTACHMENT_DOWNLOAD = '/attachment/download',
    LOGIN = '/login',
    PATIENT_ACCOUNTS = '/patient-accounts',
    PATIENT_REGISTRATION = '/patient-accounts/registration',
    PRINTABLE_ASSESSMENTS = '/printable-assessments',
    REPORTS = '/reports',
    RESOURCES = '/resources',
    CORE_MEASURES_WORKLIST = '/core-measures-worklist',
    CORE_MEASURES_FACILITY_INFO = '/core-measures-facility-info',
    CORE_MEASURES_FACILITY_INFO_POPULATION_ENTRY = '/core-measures-facility-info-population-entry/:summaryId/:facilityId/:year/:month/:isFormFacility',
    TABLET_MODE = '/tablet-mode',
}

export type AnyRoute =
    | AnonymousRoute
    | PatientRoute
    | ProviderRoute
    | ExecutiveRoute
    | SuperAdminRoute
    | AllRoutes;
