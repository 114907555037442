export enum StorageConstants {
  ActiveGroupTherapyPatientId = 'activeGroupTherapyPatientId',
  ActiveGroupTherapySessionId = 'activeGroupTherapySessionId',
  ActiveTabletModeId = 'activeTabletModeId',
  ActiveTabletModePatientId = 'activeTabletModePatientId',
  ActiveTabletModeAssessments = 'activeTabletModeAssessments',
  AnonymousAssessmentDob = 'anonymousAssessmentDob',
  LoginAuthority = 'login-authority',
  MsalAppProvider = 'msalAppProvider',
  MsalExpiration = 'msalExpiration',
  MsalHomeAccountID = 'msalHomeAccountID',
  PersistedUserAccount = 'mho-user',
  Timestamp = 'lastTimeStamp',
  UserAuthority = 'userAuth',
  AssessmentDraft = 'assessmentDraft',
  AssessmentDraftHash = 'assessmentDraftHash',
  DaisyChainCancelled = 'daisyChainCancelled',
}
