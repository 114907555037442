import * as React from 'react';
import { useContext } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import MHODateTime from 'domain/dateTime/MHODateTime';
import { UserContext } from 'context/user';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { ReactComponent as SortIcon } from 'icons/mho-sort-icon-white.svg';

import { PatientAccountsTableProps, PatientDataRow } from 'types/tableProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import Utilities from 'api/lib/Utilities';

import { HoverTableStyles } from '../styles';

const NoDataComponent: JSX.Element = <InlineText text="No Results Found." fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} />;

const columns = (isAdmin: boolean): TableColumn<PatientDataRow>[] => {
  const cols: TableColumn<PatientDataRow>[] = [
    {
      name: 'Patient Name',
      selector: (row) => row.name,
      sortable: true,
    },
  ];
  if (isAdmin) {
    cols.push({
      name: 'Account Email',
      selector: (row) => row.accountEmail,
      sortable: true,
    });
  }
  cols.push(
    {
      name: 'Account Number',
      selector: (row) => row.accountNumber,
      sortable: true,
    },
    {
      name: 'Medical Record',
      selector: (row) => row.medicalRecord,
      sortable: true,
    },
    {
      name: 'Admission Date',
      selector: (row) => row.admissionDate,
      sortable: true,
      format: (row) => (new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/).test(row.admissionDate) ? new MHODateTime(row.admissionDate).getFormattedCalendarDate() : row.admissionDate),
    },
    {
      name: 'Discharge Date',
      selector: (row) => row.dischargeDate,
      sortable: true,
      format: (row) => (new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/).test(row.dischargeDate) ? new MHODateTime(row.dischargeDate).getFormattedCalendarDate() : row.dischargeDate),
    },
    {
      name: 'Program',
      selector: (row) => row.program,
      sortable: true,
    },
    {
      name: 'Unit',
      selector: (row) => row.unit,
      sortable: true,
    },
    {
      name: 'Provider',
      selector: (row) => row.provider,
      sortable: true,
    },
  );
  return cols;
};

const PatientAccountsTable = (props: PatientAccountsTableProps): JSX.Element => {
  const { user } = useContext(UserContext);
  const isAdmin = user?.role === 'admin' || user?.role === 'super-admin';

  return (
    <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.END} extraClasses="patient-account-table">
      <DataTable
        dense
        highlightOnHover
        persistTableHead
        pagination
        paginationDefaultPage={props.defaultPage}
        paginationPerPage={30}
        sortIcon={<SortIcon />}
        customStyles={HoverTableStyles}
        columns={columns(isAdmin)}
        data={props.data}
        noDataComponent={NoDataComponent}
        onRowClicked={props.onRowClicked}
        onChangePage={(page: number, totalRows: number) => props.onChangePage?.(page, totalRows)}
        sortFunction={Utilities.caseInsensitiveSort}
        onSort={Utilities.setCellTitles}
      />
    </FlexContainer>
  );
};

export default PatientAccountsTable;
