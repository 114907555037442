import { useHistory, Prompt } from 'react-router-dom';
import { ReactComponent as X } from 'icons/mho-x-icon.svg';
import React from 'react'
import * as Survey from 'survey-react'
import AssessmentProxy from '../../../api/assessments/assessmentProxy'
import { AssessmentResult } from '../../../api/assessments/types'
import PatientUsersProxy from '../../../api/patientUsers/patientUsersProxy'
import { UserContext } from '../../../context/user'
import Button from '../../../global_elements/Button'
import { ButtonVariant } from '../../../global_elements/Button/variants'
import LabledTextInput from '../../../global_elements/Inputs/TextInput/LabledTextInput';
import { TextInputVariant } from '../../../global_elements/Inputs/TextInput/variants';
import FlexContainer from '../../../global_elements/Layouts/FlexContainer'
import { AlignVariant, DisplayVariant, JustifyVariant } from '../../../global_elements/Layouts/FlexContainer/variants'
import PageLayout from '../../../global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from '../../../global_elements/Layouts/PageLayout/variants'
import PopupWindow from '../../../global_elements/Layouts/PopupWindow'
import InlineText from '../../../global_elements/Text/InlineText'
import Paragraph from '../../../global_elements/Text/Paragraph';
import TertiaryHeader from '../../../global_elements/Text/TertiaryHeader'
import { FontColors, FontSizes } from '../../../global_elements/Text/variants'
import { PatientData } from '../../../interfaces/patients/patientData'
import { ProviderAssessmentCardProps } from '../../../types/cardProps'
import { AssessmentSurvey } from '../assessments/assessment'
import { AllRoutes } from '../../../constants/routes';

const TABLET_MODE_PIN_LENGTH = 6

const TabletModePage = (): JSX.Element => {
  const history = useHistory();
  const { activeTabletModeId, SetActiveTabletModeId } = React.useContext(UserContext);
  const { activeTabletModePatientId, SetActiveTabletModePatientId } = React.useContext(UserContext);
  const { activeTabletModeAssessments, SetActiveTabletModeAssessments } = React.useContext(UserContext);
  const [patientInfo, setPatientInfo] = React.useState<PatientData>();
  const [tabletModeAssessments] = React.useState<ProviderAssessmentCardProps[]>(
    activeTabletModeAssessments == null ? [] : JSON.parse(activeTabletModeAssessments),
  );
  const [tabletModeIsDirty, setTabletModeIsDirty] = React.useState<boolean>(false);
  const [tabletModeShowContinue, setTabletModeShowContinue] = React.useState<boolean>(false);
  const [tabletModeShowCompleteMessage, setTabletModeShowCompleteMessage] = React.useState<boolean>(false);
  const [showExitTabletModeWindow, setShowExitTabletModeWindow] = React.useState<boolean>(false);
  const [exitTabletModePin, setExitTabletModePin] = React.useState<string>('');
  const [exitTabletModeErrorMessage, setExitTabletModeErrorMessage] = React.useState<string>('');

  const handlePageClosePrompt = ((event: any): any => {
    event.preventDefault();
    event.returnValue = '';
  });

  React.useEffect(() => {
    if (tabletModeIsDirty) {
      window.addEventListener('beforeunload', handlePageClosePrompt);
    }

    return () => {
      window.removeEventListener('beforeunload', handlePageClosePrompt);
    }
  }, [tabletModeIsDirty]);

  React.useEffect(() => {
    const patientId = parseInt(activeTabletModePatientId!, 10);
    PatientUsersProxy.getPatient(patientId,
      (response) => {
        if (response.data) {
          const loadedPatientData: PatientData = response.data[0];
          setPatientInfo(loadedPatientData);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      });
  }, [activeTabletModePatientId]);

  const removeCurrentAssessment = (): void => {
    tabletModeAssessments.splice(0, 1);
    SetActiveTabletModeAssessments(JSON.stringify(tabletModeAssessments));
  };

  const tabletModeAdvance = (): void => {
    if (tabletModeAssessments.length > 1) {
      setTabletModeShowContinue(true);
    } else {
      removeCurrentAssessment();
      setTabletModeShowCompleteMessage(true);
    }
  };

  const tabletModeOnCancel = (): void => {
    tabletModeAdvance();
  };

  const tabletModeOnComplete = async (survey: Survey.Model, options: any): Promise<void> => {
    options.showDataSaving();
    setTabletModeIsDirty(false);
    const results = Object.entries(survey.data);

    // Construct the data format for the API.
    const resultsList: AssessmentResult[] = []
    for (let i = 0; i < results.length; i += 1) {
      const resultRow = {
        patientID: tabletModeAssessments[0].patientID,
        assessmentNumber: parseInt(tabletModeAssessments[0].assessmentNumber, 10),
        variableName: results[i][0],
        dataValue: String(results[i][1]),
      };
      resultsList.push(resultRow)
    }

    try {
      await AssessmentProxy.saveAssessmentForm(resultsList)

      options.showDataSavingSuccess()

      setTimeout(() => {
        tabletModeAdvance();
      }, 1000);
    } catch (error) {
      console.error(error)

      // Slight delay to show loading message briefly
      setTimeout(() => {
        options.showDataSavingError()
      }, 500);

      throw error
    }
  };

  const tabletModeOnContinue = (): void => {
    removeCurrentAssessment();
    setTabletModeShowContinue(false);
  };

  const exitTabletMode = (): void => {
    setShowExitTabletModeWindow(true);
  };

  const verifyExitTabletModePin = (): void => {
    AssessmentProxy.verifyTabletModePin(activeTabletModeId!, exitTabletModePin)
      .then((r) => {
        if (!r.success) {
          setExitTabletModeErrorMessage(r.message!);
        } else {
          const patientId = activeTabletModePatientId;
          setExitTabletModeErrorMessage('');
          SetActiveTabletModePatientId(null);
          SetActiveTabletModeAssessments(null);
          SetActiveTabletModeId(null);
          setTabletModeShowCompleteMessage(false);
          history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${patientId}`);
        }
      });
  };

  if (tabletModeShowContinue) {
    return (
      <PageLayout
        layout={PageLayoutVariant.PADDED}
        testText="Assessment Form Page"
        hideHeader
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="assessment-form-continue-container"
        >
          <InlineText
            text="Continue to the next assessment."
            fontColor={FontColors.PRIMARY}
            fontSize={FontSizes.SUPER_EXTRA_LARGE}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={tabletModeOnContinue}
          >
            <InlineText
              text="Continue"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
        </FlexContainer>
      </PageLayout>
    );
  }

  if (tabletModeShowCompleteMessage || tabletModeAssessments.length === 0) {
    return (
      <PageLayout
        layout={PageLayoutVariant.PADDED}
        testText="Assessment Form Page"
        hideHeader
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="exit-tablet-mode-container"
        >
          <InlineText
            text="Thank you, please pass the tablet back to your provider."
            fontColor={FontColors.PRIMARY}
            fontSize={FontSizes.SUPER_EXTRA_LARGE}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={exitTabletMode}
          >
            <InlineText
              text="Exit Tablet Mode"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
          {showExitTabletModeWindow && (
            <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="popup-window-wrapper">
              <PopupWindow>
                <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="popup-window-content">
                  <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.CENTER} extraClasses="popup-window-header">
                    <TertiaryHeader text="Tablet Mode PIN Verification" fontColor={FontColors.PRIMARY} />
                    <Button
                      variant={ButtonVariant.INVISIBLE}
                      onClick={(e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        setShowExitTabletModeWindow(false);
                      }}
                    >
                      <X />
                    </Button>
                  </FlexContainer>
                  <form className="exit-tablet-mode-pin-verification" onSubmit={verifyExitTabletModePin}>
                    <LabledTextInput
                      label="PIN (6 digits)"
                      placeholder="Enter the PIN set for tablet mode."
                      name="exit-pin"
                      value={exitTabletModePin}
                      type="password"
                      onChange={(e) => setExitTabletModePin(e.target.value.replace(/[^0-9]/g, '').substring(0, TABLET_MODE_PIN_LENGTH))}
                      variant={TextInputVariant.PRIMARY}
                      maxTextLength={TABLET_MODE_PIN_LENGTH}
                      autoComplete="new-password"
                      autoFocus
                      inputMode="numeric"
                    />
                    {exitTabletModeErrorMessage !== '' && (
                      <Paragraph
                        text={exitTabletModeErrorMessage}
                        fontColor={FontColors.HIGH_PRIORITY}
                        fontSize={FontSizes.REGULAR}
                        extraClasses="error-message"
                        bold
                      />
                    )}
                    <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.CENTER} extraClasses="popup-window-buttons">
                      <Button variant={ButtonVariant.SECONDARY} onClick={() => setShowExitTabletModeWindow(false)}>
                        <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                      </Button>
                      <Button variant={ButtonVariant.PRIMARY} disabled={exitTabletModePin.length !== TABLET_MODE_PIN_LENGTH} submit onClick={verifyExitTabletModePin} testid="submit-send-resend-button">
                        <InlineText text="Exit" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                      </Button>
                    </FlexContainer>
                  </form>
                </FlexContainer>
              </PopupWindow>
            </FlexContainer>
          )}
        </FlexContainer>
      </PageLayout>
    )
  }

  return (
    <>
      {patientInfo && (
        <AssessmentSurvey
          assessmentNumber={parseInt(tabletModeAssessments[0].assessmentNumber, 10)}
          appliedWhenID={tabletModeAssessments[0].appliedWhenID}
          patientAssessmentData={{
            programCode: patientInfo.programCode,
            patientNumber: patientInfo.patientNumber,
            treatmentTypeID: patientInfo.treatmentTypeID,
            corporationID: patientInfo.corporationID,
            populationTypeID: patientInfo.populationTypeID,
            patientID: patientInfo.patientID!,
            accountID: patientInfo.accountID!,
          }}
          onCompleteCallback={tabletModeOnComplete}
          setIsDirtyCallback={setTabletModeIsDirty}
          onCancelCallback={tabletModeOnCancel}
          hideHeader
          isPatient
          showExtremeMessages={false}
        />
      )}
      <Prompt when={tabletModeIsDirty} message="Are you sure you want to close the form? Incomplete assessments will be lost." />
    </>
  );
}

export default TabletModePage
