import ApiProxy from 'api/lib/ApiService';
import ApiProxyV2 from 'api/lib/ApiService/v2';
import ApiResponse from 'api/lib/models/ApiResponse';
import ApiResponseV2 from 'api/lib/models/ApiResponse/v2';
import { AssessmentDefinition } from 'interfaces/assessments/assessmentDefinitionSearchResponse';
import { AssessmentResultsWithExtremeDefinition } from 'domain/Forms/MHO/AssessmentResultsDefinition';
import { PatientAssessmentDefinition } from 'domain/Forms/MHO/PatientAssessmentDefinition';
import { AssessmentScoreDefinition } from 'domain/Forms/MHO/AssessmentScoreDefinition';
import { AssessmentSubscales } from 'domain/Forms/MHO/AssessmentSubscales';
import { URLS } from 'constants/appUrls';
import { AssessmentAppliedWhen, AssessmentSetupMode, AssessmentSetupModeSequence } from 'constants/assessment_types';
import AssessmentDefinitionProxy from './assessmentDefinitionProxy';
import { AssessmentLinkData, AssessmentResult, EventAssessmentResult, SaveTabletModeSessionArgs, SendAssessmentLinkArgs, SendAssessmentLinkResponse } from './types';

/**
 * AssessmentProxy. A Proxy/wrapper object around all the API calls to save/retrieve
 * assessment results.
 */
class AssessmentProxy {
  /**
   * Retrieve a templated list from the API.
   *
   * @param url API url to get
   * @returns {Promise} templated list, or an empty list.
   */
  private static getApiDataList<T>(url: string): any {
    return new Promise((resolve, reject) => {
      ApiProxy.get<T>(
        url,
        (response: ApiResponse<T>) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  static async saveAssessmentForm(assessmentResults: AssessmentResult[], groupTherapyId?: string): Promise<void> {
    let url = `${URLS.MHO_API}/FormRest`;

    if (groupTherapyId) {
      url += `?gpid=${groupTherapyId}`;
    }

    return new Promise((resolve, reject) => {
      ApiProxy.post<void>(
        url,
        JSON.stringify(assessmentResults),
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  static async saveAnonAssessmentForm(assessmentResults: AssessmentResult[], accountId: number, assessmentLinkHash: string, dob: Date): Promise<void> {
    const dobParam = dob.toISOString().split('T')[0];
    try {
      await fetch(`${URLS.MHO_API}/AssessmentLinkRest/${accountId}/${assessmentLinkHash}/${dobParam}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(assessmentResults),
      });
    } catch (error) {
      console.error('Error saving assessment form', error);
      throw error;
    }
  }

  static async saveEventAssessmentForm(assessmentResults: EventAssessmentResult[]): Promise<void> {
    const url = `${URLS.MHO_API}/CMFormRest`;

    return new Promise((resolve, reject) => {
      ApiProxy.post<void>(
        url,
        JSON.stringify(assessmentResults),
        (response: any) => {
          resolve(response);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  /**
   * Creates an url argument in a format of '?mode=${mode}&seq=${seq}'.  The function correctly adjusts
   * on whether or not there are any arg to pre-pend the '?' and whether there are more than one arg
   * to join with '&'.
   * Note: The API only takes 'mode=Subscales' for both components and subscales.
   *
   * @param setupModeSequence data buffer that potentially contains the SetupMode and the sequence #
   * @param appliedWhenSequenceNumber sequence number when multiple assessment exists with the same applied when order.
   * @returns compounded url argument that starts with '?' if one or more arg exists, and joined with '&'
   *          if more than 1 arg exists.
   */
  static convertSetupModeSequenceToUrlPath = (setupModeSequence?: AssessmentSetupModeSequence, appliedWhenSequenceNumber?: number): string => {
    let modeSeqStr = '';
    const optionList: string[] = [];
    let isSubscales = false;
    if (setupModeSequence?.mode && setupModeSequence.mode !== AssessmentSetupMode.OVERALL && setupModeSequence.mode !== AssessmentSetupMode.CORE_MEASURES) {
      optionList.push(`mode=${AssessmentSetupMode.SUBSCALES}`);
      isSubscales = true;
    }

    // 'seq' is used for something else for isSubscales, but leaving this guard in for now.
    // Some discussions of adding 'subscaleSequence' option as a replacement.
    if (!isSubscales && setupModeSequence?.subscaleSequence && setupModeSequence.subscaleSequence >= 0) {
      optionList.push(`seq=${setupModeSequence.subscaleSequence}`);
    } else if (!Number.isNaN(appliedWhenSequenceNumber)) {
      optionList.push(`seq=${appliedWhenSequenceNumber}`);
    }

    if (optionList.length) {
      modeSeqStr = `?${optionList.join('&')}`;
    }

    return modeSeqStr;
  };

  // Deprecated
  // /**
  //  * Retrieve the assessment results/responses that the patient have taken in the past.
  //  *
  //  * @param patientID ID for the patient in question.
  //  * @param assessmentNumber identifying assessment number for the form.
  //  * @param instrumentTypeID identifying instrument type ID for the form.
  //  * @param appliedWhenID identifying applied when for the form.
  //  * @param setupModeSequence optional filter to find component/subscales and sequences if necessary.
  //  * @returns {Promise} of the results definition of the assessments.
  //  */
  // static getAssessmentResults(
  //   patientID: number, assessmentNumber: number, instrumentTypeID: number, appliedWhenID: number,
  //   setupModeSequence?: AssessmentSetupModeSequence,
  // ): Promise<AssessmentResultsDefinition[]> {
  //   return new Promise<AssessmentResultsDefinition[]>((resolve, reject) => {
  //     const modeSeqStr: string = this.convertSetupModeSequenceToUrlPath(setupModeSequence);
  //     const url = `${URLS.MHO_API}/FormRest/${patientID}/${instrumentTypeID}/${assessmentNumber}/${appliedWhenID}${modeSeqStr}`;

  //     ApiProxy.get<AssessmentResultsDefinition[]>(url, (response: ApiResponse<AssessmentResultsDefinition[]>) => {
  //       resolve(response?.data || []);
  //     }, (response: any) => {
  //       reject(response);
  //     });
  //   });
  // }

  /**
   * Retrieve the assessment results/responses that the patient have taken in the past, that includes the
   * assessment's extreme responses.
   *
   * @param patientID ID for the patient in question.
   * @param assessmentNumber identifying assessment number for the form.
   * @param appliedWhenID identifying applied when for the form.
   * @param appliedWhenSequenceNumber sequence number when multiple assessments exist with the same applied when order.
   * @param setupModeSequence optional filter to find component/subscales and sequences if necessary.
   * @returns {Promise} of the results definition of the assessments.
   */
  static getAssessmentResultsWithExtreme(
    patientID: number,
    assessmentNumber: number,
    appliedWhenID: number,
    appliedWhenSequenceNumber: number,
    setupModeSequence?: AssessmentSetupModeSequence,
  ): Promise<any> {
    return new Promise<AssessmentResultsWithExtremeDefinition[]>((resolve, reject) => {
      const modeSeqStr: string = this.convertSetupModeSequenceToUrlPath(setupModeSequence, appliedWhenSequenceNumber);
      AssessmentDefinitionProxy.findExactAssessment(assessmentNumber).then((assessment: AssessmentDefinition | null) => {
        const instrumentTypeID = assessment?.instrumentTypeID;
        const url = `${URLS.MHO_API}/FormResWiEx/${patientID}/${instrumentTypeID}/${assessmentNumber}/${appliedWhenID}${modeSeqStr}`;

        ApiProxy.get<AssessmentResultsWithExtremeDefinition[]>(
          url,
          (response: ApiResponse<AssessmentResultsWithExtremeDefinition[]>) => {
            resolve(response?.data || []);
          },
          (response: any) => {
            reject(response);
          },
        );
      });
    });
  }

  /**
   * Retrieve a list of patient's score for all of the assessments, a given instrument type ID.
   *
   * @param patientID a patient's ID to query.
   * @param instrumentTypeID an ID for the instrument, of which for all assessment of that type.
   * @param isOverallView optional flag to decide whether to get the overall or a subscale score.
   * @return {Promise} list of patient assessment scores
   */
  static getPatientAssessmentScore(patientID: number, instrumentTypeID: number, isOverallView = true): Promise<AssessmentScoreDefinition[]> {
    return new Promise((resolve, reject) => {
      const modeArg = isOverallView ? '' : 'mode=Subscales&';
      const url = `${URLS.MHO_API}/FormScores/${instrumentTypeID}?${modeArg}pid=${patientID}`;

      ApiProxy.get<AssessmentScoreDefinition[]>(
        url,
        (response: ApiResponse<AssessmentScoreDefinition[]>) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  /**
   * Retrieve a list of user's score for all of the assessments, given instrument type ID.
   *
   * @param careID a person's ID to query.
   * @param instrumentTypeID an ID for the instrument, of which for all assessment of that type.
   * @param isOverallView optional flag to decide whether to get the overall or a subscale score.
   * @return {Promise} list of user assessment scores
   */
  static getUserAssessmentScore(careID: number, instrumentTypeID: number, isOverallView = true): Promise<AssessmentScoreDefinition[]> {
    return new Promise((resolve, reject) => {
      const modeArg = isOverallView ? '' : 'mode=Subscales&';
      const url = `${URLS.MHO_API}/FormScores/${instrumentTypeID}?${modeArg}cid=${careID}`;

      ApiProxy.get<AssessmentScoreDefinition[]>(
        url,
        (response: ApiResponse<AssessmentScoreDefinition[]>) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  /**
   * Retrieve a list of patient's assessments taken that matches the instrumentTypeID.
   *
   * @param patientID a patient's ID to query.
   * @param instrumentTypeID an ID for the instrument, of which for all assessment of that type.
   * @return {Promise} list of patient's assessments taken.
   */
  static getPatientTakenAssessments(patientID: number, instrumentTypeID: number): Promise<PatientAssessmentDefinition[]> {
    const url = `${URLS.MHO_API}/FormTaken/${instrumentTypeID}?pid=${patientID}`;
    return this.getApiDataList<AssessmentScoreDefinition[]>(url);
  }

  /**
   * Retrieve a list of user's assessments taken that matches the instrumentTypeID.
   *
   * @param careID a person's ID to query.
   * @param instrumentTypeID an ID for the instrument, of which for all assessment of that type.
   * @return {Promise} list of patient's assessments taken.
   */
  static getUserTakenAssessments(careID: number, instrumentTypeID: number): Promise<PatientAssessmentDefinition[]> {
    const url = `${URLS.MHO_API}/FormTaken/${instrumentTypeID}?cid=${careID}`;
    return this.getApiDataList<AssessmentScoreDefinition[]>(url);
  }

  static getAssessmentSubscales(instrumentTypeID: number): Promise<AssessmentSubscales[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/ListSubscales/${instrumentTypeID}`;

      ApiProxy.get<AssessmentSubscales[]>(
        url,
        (response: ApiResponse<AssessmentSubscales[]>) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  static async getAssessmentLinkData(hash: string, patientDob: Date): Promise<ApiResponseV2<AssessmentLinkData[] | null | undefined>> {
    const formattedDate = patientDob.toISOString().split('T')[0];
    const response = await ApiProxyV2.get<AssessmentLinkData[]>(`${URLS.MHO_API}/AssessmentLink/${hash}/${formattedDate}`);
    return response;
  }

  /**
   * Send an email to provided address containing a link to the provided assessment.
   *
   * @param args SendAssessmentLinkArgs contaning relevant data to construct the assessment link and send it to the patient.
   * @return {Promise} the hash generated for he assessment link.
   */
  static sendAssessmentLink(args: SendAssessmentLinkArgs): Promise<SendAssessmentLinkResponse> {
    return new Promise((resolve, reject) => {
      console.log(args);
      const url = `${URLS.MHO_API}/AssessmentLink`;

      ApiProxy.post<string>(
        url,
        [args],
        (response) => {
          if (!response.data) {
            reject(response);
            return;
          }
          resolve({ hash: response.data });
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  static verifyLinkHash(hash: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/VerifyHash/${hash}`;

      ApiProxy.get<boolean>(
        url,
        () => {
          resolve();
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  static saveTabletModeSession(data: SaveTabletModeSessionArgs): Promise<ApiResponseV2<number>> {
    const url = `${URLS.MHO_API}/TabletAssmnts`;
    return ApiProxyV2.postWithResponse<SaveTabletModeSessionArgs[], number>(url, [data]);
  }

  static verifyTabletModePin(tabletModeId: string, pin: string): Promise<ApiResponseV2<undefined>> {
    const url = `${URLS.MHO_API}/VerifyTabletModePIN/${tabletModeId}/${pin}`;
    return ApiProxyV2.get(url, undefined, false, true);
  }

  static async getAppliedWhens(): Promise<AssessmentAppliedWhen[]> {
    const url = `${URLS.MHO_API}/aw`
    const response = await ApiProxy.alternativeGet<AssessmentAppliedWhen[]>(url, {}).then()
    return response.data || []
  }

  static saveAppliedWhen(documentId: number, appliedWhenId: number): Promise<ApiResponseV2<undefined>> {
    const url = `${URLS.MHO_API}/SaveDocAW/${documentId}/${appliedWhenId}`;
    return ApiProxyV2.post(url, undefined);
  }
}

export default AssessmentProxy;
