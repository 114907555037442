import { useEffect, useState } from 'react'
import * as Survey from 'survey-react'
import { Prompt } from 'react-router-dom'

import AssessmentProxy from 'api/assessments/assessmentProxy'
import { AssessmentLinkData, AssessmentResult } from 'api/assessments/types'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import InlineText from 'global_elements/Text/InlineText'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import { AssessmentSurvey } from 'pages/shared/assessments/assessment'

import './AssessmentForm.scss'
import Button from '../../../global_elements/Button'
import { ButtonVariant } from '../../../global_elements/Button/variants'

interface Props {
  assessmentLinkHash: string
  assessmentLinkData: AssessmentLinkData[]
}

export default function AssessmentForm({
  assessmentLinkHash,
  assessmentLinkData,
}: Props): JSX.Element {
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [showCompleteMessage, setShowCompleteMessage] = useState<boolean>(false)
  const [showContinue, setShowContinue] = useState<boolean>(false);

  const handlePageClosePrompt = ((event: any): any => {
    event.preventDefault();
    event.returnValue = '';
  })

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', handlePageClosePrompt)
    }

    return () => {
      window.removeEventListener('beforeunload', handlePageClosePrompt)
    }
  }, [isDirty])

  const onComplete = async (survey: Survey.Model, options: any): Promise<void> => {
    options.showDataSaving()
    setIsDirty(false)
    const results = Object.entries(survey.data)

    // Construct the data format for the API.
    const resultsList: AssessmentResult[] = []
    for (let i = 0; i < results.length; i += 1) {
      const resultRow = {
        patientID: assessmentLinkData[0].patientID,
        assessmentNumber: assessmentLinkData[0].assessmentNumber,
        variableName: results[i][0],
        dataValue: String(results[i][1]),
      };
      resultsList.push(resultRow)
    }

    try {
      await AssessmentProxy.saveAnonAssessmentForm(
        resultsList,
        assessmentLinkData[0].accountID!,
        assessmentLinkHash,
        new Date(assessmentLinkData[0].dateOfBirth!),
      )

      options.showDataSavingSuccess()

      setTimeout(() => {
        if (assessmentLinkData.length > 1) {
          setShowContinue(true)
        } else {
          setShowCompleteMessage(true);
        }
      }, 1000);
    } catch (error) {
      console.error(error)

      // Slight delay to show loading message briefly
      setTimeout(() => {
        options.showDataSavingError()
      }, 500);

      throw error
    }
  };

  const onContinue = (): void => {
    assessmentLinkData.splice(0, 1);
    setShowContinue(false);
  };

  if (showContinue) {
    return (
      <PageLayout
        layout={PageLayoutVariant.PADDED}
        testText="Assessment Form Page"
        hideHeader
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="assessment-form-continue-container"
        >
          <InlineText
            text="Assessment submitted. Continue to the next one."
            fontColor={FontColors.PRIMARY}
            fontSize={FontSizes.SUPER_EXTRA_LARGE}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={onContinue}
          >
            <InlineText
              text="Continue"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
        </FlexContainer>
      </PageLayout>
    )
  }

  if (showCompleteMessage) {
    return (
      <PageLayout
        layout={PageLayoutVariant.PADDED}
        testText="Assessment Form Page"
        hideHeader
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="assessment-form-complete-container"
        >
          <InlineText
            text="Thank you for completing the assessment. You may now close this window."
            fontColor={FontColors.PRIMARY}
            fontSize={FontSizes.SUPER_EXTRA_LARGE}
          />
        </FlexContainer>
      </PageLayout>
    )
  }

  return (
    <>
      <AssessmentSurvey
        assessmentNumber={assessmentLinkData[0].assessmentNumber}
        appliedWhenID={assessmentLinkData[0].appliedWhenID}
        patientAssessmentData={{
          programCode: assessmentLinkData[0].programCode,
          patientNumber: assessmentLinkData[0].patientNumber,
          treatmentTypeID: assessmentLinkData[0].treatmentTypeID,
          corporationID: assessmentLinkData[0].corporationID,
          populationTypeID: assessmentLinkData[0].populationTypeID,
          patientID: assessmentLinkData[0].patientID,
          accountID: assessmentLinkData[0].accountID,
        }}
        onCompleteCallback={onComplete}
        setIsDirtyCallback={setIsDirty}
        hideHeader
        isPatient
        extraClasses="assessment-form-page"
        showExtremeMessages={false}
        jsonDefinition={assessmentLinkData[0].assessment}
        addCancelButton={false}
      />
      <Prompt when={isDirty} message="Are you sure you want to close the form? Incomplete assessments will be lost." />
    </>
  )
}
