import { useState, useRef } from 'react'
import moment, { Moment } from 'moment'

import { LabeledDatePickerInput } from 'global_elements/Inputs/LabeledDatePickerInput'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import PrimaryHeader from 'global_elements/Text/PrimaryHeader'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import Button from 'global_elements/Button'
import { ButtonVariant } from 'global_elements/Button/variants'
import InlineText from 'global_elements/Text/InlineText'
import SecondaryHeader from 'global_elements/Text/SecondaryHeader'
import AssessmentProxy from 'api/assessments/assessmentProxy'

import ApiResponse from 'api/lib/models/ApiResponse'
import { HttpStatus } from 'constants/httpStatus'
import { AssessmentLinkData } from 'api/assessments/types'

import './AssessmentVerify.scss'

interface Props {
  assessmentLinkHash: string
  onVerified: (data: AssessmentLinkData[]) => void
}

export default function AssessmentVerify({
  assessmentLinkHash,
  onVerified,
}: Props): JSX.Element {
  const [selectedDob, setSelectedDob] = useState<Date | undefined>()
  const [dobError, setDobError] = useState<string | undefined>()
  const continueButtonRef = useRef<HTMLButtonElement>(null)

  const handleDobChange = (date: Date | null): void => {
    setSelectedDob(date ?? undefined)
    setDobError(undefined)
    continueButtonRef.current?.focus()
  }

  const inputValid = (): boolean => {
    if (!selectedDob) {
      return false
    }

    return true
  }

  const handleContinue = async (): Promise<void> => {
    if (!selectedDob) {
      setDobError('Please enter your date of birth')
      return
    }

    try {
      const response = await AssessmentProxy.getAssessmentLinkData(assessmentLinkHash, selectedDob)
      if (!response.success) {
        setDobError(response.message);
        return;
      }
      if (!response.data) {
        setDobError('An unexpected error occurred')
        console.error('Received null response from getAssessmentLinkData')
        return
      }
      onVerified(response.data)
    } catch (error: any) {
      console.error('getAssessmentLinkData error', error)
      if (error instanceof ApiResponse && error.status === HttpStatus.BADREQUEST) {
        setDobError('Incorrect date of birth')
      } else {
        console.error('getAssessmentLinkData error', error)
        setDobError('An unexpected error occurred')
      }
    }
  }

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Assessment Verify Page"
      hideHeader
      extraClasses="assessment-verify-page-layout"
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="assessment-verify-page"
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
        >
          <PrimaryHeader text="Assessment" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
          <SecondaryHeader text="Please enter your date of birth" fontColor={FontColors.SECONDARY} marginBottomPx={16} />
          <LabeledDatePickerInput
            label="Date of Birth (mm/dd/yyyy)"
            name="dob"
            onChange={handleDobChange}
            error={dobError}
            isValidDate={(current: Moment): boolean => (
              current.isBefore(moment())
            )}
            testid="dob-input"
          />
        </FlexContainer>
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.CENTER}
          justify={JustifyVariant.END}
          extraClasses="assessment-verify-page__button-container"
        >
          <Button
            ref={continueButtonRef}
            variant={ButtonVariant.PRIMARY}
            onClick={handleContinue}
            testid="continue-button"
            disabled={!inputValid()}
            submit
          >
            <InlineText
              text="Continue"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
        </FlexContainer>
      </FlexContainer>
    </PageLayout>
  )
}
