import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useContext } from 'react';

import AssessmentPage from 'pages/shared/Survey';
import AssessmentResultsPage from 'pages/shared/AssessmentResult/assessmentResults';
import ProviderDashboardPage from 'pages/provider/ProviderDashboard';
import PatientAccountsPage from 'pages/shared/PatientAccounts';
import PatientDetailsPage from 'pages/shared/PatientDetails';
import PatientRegistrationPage from 'pages/shared/PatientRegistration';
import PatientAccountSetupPage from 'pages/shared/PatientAccountSetup';
import FacilityUserSettingsPage from 'pages/shared/FacilityUserSettings';
import PrintableAssessmentsPage from 'pages/shared/PrintableAssessments';
import { PDFFileDownload } from 'pages/shared/PrintableAssessments/download';
import { HelpFileDownload } from 'pages/shared/Help/download';
import HelpPage from 'pages/shared/Help';
import GroupTherapyStartSessionPage from 'pages/shared/GroupTherapyStartSession';
import GroupTherapyAddPatientsPage from 'pages/shared/GroupTherapyAddPatients';
import GroupTherapyAddAssessmentsPage from 'pages/shared/GroupTherapyAddAssessments';
import GroupTherapyParticipantsPage from 'pages/shared/GroupTherapyParticipants';
import GroupTherapyAssessmentsPage from 'pages/shared/GroupTherapyAssessments';
import GroupTherapyExitSessionPage from 'pages/shared/GroupTherapyExitSession';
import GroupTherapyDashboardPage from 'pages/shared/GroupTherapyDashboardPage';
import Page404 from 'pages/errors/404';
import CoreMeasuresWorklistPage from 'pages/shared/CoreMeasuresWorklist';
import CoreMeasuresFacilityInfoPage from 'pages/shared/CoreMeasuresFacilityInfo';
import CoreMeasuresFacilityInfoPopulationEntryPage from 'pages/shared/CoreMeasuresFacilityInfoPopulationEntry'

import { ProviderRoutes, AllRoutes } from 'constants/routes';
import { UserContext } from 'context/user';
import { AttachmentDownload } from '../../shared/Attachments/download';
import FacilityLibraryPage from '../../shared/FacilityLibrary';
import TabletModePage from '../../shared/TabletMode';

const ProviderRouting = (): JSX.Element => {
  const { activeTabletModeId } = useContext(UserContext);
  const { activeGroupTherapySessionId, activeGroupTherapyPatientId } = useContext(UserContext);

  if (activeTabletModeId) {
    return (
      <Router>
        <Switch>
          {activeTabletModeId && (
            <Route
              path={`${AllRoutes.TABLET_MODE}`}
              component={TabletModePage}
            />
          )}
          <Redirect to={`${AllRoutes.TABLET_MODE}`} />
        </Switch>
      </Router>
    );
  }

  // When a group session is active, the user may not visit any other pages
  if (activeGroupTherapySessionId) {
    const groupSessionUrl = AllRoutes.GROUP_THERAPY_PARTICIPANTS.replace(':groupId', activeGroupTherapySessionId)
    return (
      <Router>
        <Switch>
          <Route
            path={AllRoutes.GROUP_THERAPY_PARTICIPANTS}
            component={GroupTherapyParticipantsPage}
          />
          {activeGroupTherapyPatientId && (
            <Route
              path={AllRoutes.GROUP_THERAPY_ASSESSMENTS.replace(':patientId', activeGroupTherapyPatientId)}
              component={GroupTherapyAssessmentsPage}
            />
          )}
          <Route
            path={AllRoutes.GROUP_THERAPY_EXIT_SESSION}
            component={GroupTherapyExitSessionPage}
          />
          <Redirect
            to={groupSessionUrl}
          />
        </Switch>
      </Router>
    )
  }

  return (
    <Router>
      <Switch>
        <Redirect
          exact
          path={AllRoutes.LOGIN}
          to={ProviderRoutes.DASHBOARD}
        />
        <Route
          exact
          path={ProviderRoutes.DASHBOARD}
          component={ProviderDashboardPage}
        />
        <Route
          exact
          path={ProviderRoutes.PATIENT_ACCOUNTS}
          component={PatientAccountsPage}
        />
        <Route
          exact
          path={`${ProviderRoutes.PATIENT_REGISTRATION}/:facilityID`}
          component={PatientRegistrationPage}
        />
        <Route
          exact
          path={`${ProviderRoutes.PATIENT_ACCOUNTS}/:patient`}
          component={PatientDetailsPage}
        />
        <Route
          exact
          path={`${ProviderRoutes.PATIENT_ACCOUNTS}/:patient/account-setup`}
          component={PatientAccountSetupPage}
        />
        <Route
          exact
          path={`${AllRoutes.GROUP_THERAPY_BASE}`}
          component={GroupTherapyDashboardPage}
        />
        <Route
          exact
          path={`${AllRoutes.GROUP_THERAPY_START_SESSION}`}
          component={GroupTherapyStartSessionPage}
        />
        <Route
          path={`${AllRoutes.GROUP_THERAPY_BASE}/:groupId/add-patients`}
          component={GroupTherapyAddPatientsPage}
        />
        <Route
          path={`${AllRoutes.GROUP_THERAPY_BASE}/:groupId/add-assessments`}
          component={GroupTherapyAddAssessmentsPage}
        />
        <Route
          path={`${AllRoutes.GROUP_THERAPY_BASE}/:groupId/participants`}
          component={GroupTherapyParticipantsPage}
        />
        <Route
          path={ProviderRoutes.PRINTABLE_ASSESSMENTS}
          component={PrintableAssessmentsPage}
        />
        <Route
          path={ProviderRoutes.ACCOUNT}
          component={FacilityUserSettingsPage}
        />
        <Route
          path={`${AllRoutes.DOWNLOAD}/:filename`}
          component={PDFFileDownload}
        />
        <Route
          path={`${AllRoutes.ATTACHMENT_DOWNLOAD}/:attachmentId`}
          component={AttachmentDownload}
        />
        <Route
          path={`${AllRoutes.HELP_DOWNLOAD}/:filename`}
          component={HelpFileDownload}
        />
        <Route
          path={ProviderRoutes.HELP}
          component={HelpPage}
        />
        <Route
          path={`${ProviderRoutes.ASSESSMENT}/:assessmentNumber/:instrumentTypeID/:appliedWhenID/:patientID`}
          component={AssessmentPage}
        />
        <Route
          path={`${ProviderRoutes.ASSESSMENT_RESULTS}/:careID/:instrumentTypeID/:patientID`}
          component={AssessmentResultsPage}
        />
        <Route
          path={ProviderRoutes.CORE_MEASURES_WORKLIST}
          component={CoreMeasuresWorklistPage}
        />
        <Route
          path={ProviderRoutes.CORE_MEASURES_FACILITY_INFO}
          component={CoreMeasuresFacilityInfoPage}
        />
        <Route
          path={`${ProviderRoutes.CORE_MEASURES_FACILITY_INFO_POPULATION_ENTRY}`}
          component={CoreMeasuresFacilityInfoPopulationEntryPage}
        />
        <Route
          path={`${ProviderRoutes.FACILITY_LIBRARY}`}
          component={FacilityLibraryPage}
        />
        <Route component={Page404} />
      </Switch>
    </Router>
  )
};

export default ProviderRouting;
