import { ReactComponent as X } from 'icons/mho-x-icon.svg';
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import CollapsibleSection from 'global_elements/Layouts/CollapsibleSection';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import ProviderAssessmentCard from 'global_elements/Layouts/Cards/ProviderAssessmentCard';
import PatientInfoCard from 'global_elements/Layouts/Cards/PatientInfoCard';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { PatientData, getNewPatientData } from 'interfaces/patients/patientData';
import { AssessmentCardSectionContainer, ProviderAssessmentCardProps } from 'types/cardProps';
import FacilityUsersProxy from 'api/facilityUsers/facilityUsersProxy';
import { SaveTabletModeSessionArgsAssessment, SendAssessmentLinkArgsAssessment } from 'api/assessments/types';
import { AssessmentLocationState } from 'types/CoreMeasurePatient';
import { AssessmentData } from 'interfaces/assessments/assessmentData';
import { AssessmentAppliedWhen, ProviderAssessmentTypes } from 'constants/assessment_types';
import MHODateTime from 'domain/dateTime/MHODateTime';
import { UserContext } from 'context/user';
import { AccessType } from 'types/facilityUserAccount';

import FeatureFlagManager from 'config/featureFlags/FeatureFlagManager';
import { FeatureFlagName } from 'config/featureFlags';
import * as EmailInput from 'global_elements/Inputs/TextInput/lib/email';
import moment from 'moment';
import ApiDataUiConverter from '../assessments/ApiDataUiConvert';
import './styles.scss';
import Button from '../../../global_elements/Button';
import { ButtonVariant } from '../../../global_elements/Button/variants';
import PopupWindow from '../../../global_elements/Layouts/PopupWindow';
import AssessmentProxy from '../../../api/assessments/assessmentProxy';

import LabledTextInput from '../../../global_elements/Inputs/TextInput/LabledTextInput';
import { TextInputVariant } from '../../../global_elements/Inputs/TextInput/variants';
import LabledSingleSelect from '../../../global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';
import { SingleStandardDropdownStyles } from '../../../global_elements/Inputs/Dropdown/SingleSelect/styles';
import { SelectOption } from '../../../types/inputProps';
import { Roles } from '../../../constants/roles';
import Paragraph from '../../../global_elements/Text/Paragraph';

type uriParams = {
  patient: string;
};

/**
 * Checks and gets the list of assessment card props from the API assessment list.
 *
 * @param assessmentList list of API assessment list. Could be null.
 * @param assessmentTypeFilter optional filter to narrow the type 'ToDo', 'SelfCheck' and 'History'.
 * @returns a converted API assessment list to Card Prop.
 */
function getAssessmentCardProps(assessmentList: AssessmentData[] | null, assessmentTypeFilter?: ProviderAssessmentTypes, isCoreMeasuresMode?: boolean): ProviderAssessmentCardProps[] {
  let cardProps: ProviderAssessmentCardProps[] = [];
  if (assessmentList) {
    cardProps = ApiDataUiConverter.convertProviderAssessmentsToCardProps(assessmentList, assessmentTypeFilter, isCoreMeasuresMode);
  }
  return cardProps;
}

const TABLET_MODE_PIN_LENGTH = 6
const TABLET_MODE_PIN_INSTRUCTIONS = 'Choose a 6 digit numeric PIN for Tablet mode. Remember this PIN; it will be required to exit Tablet mode.'

const PatientDetails = (): JSX.Element => {
  const { patient } = useParams<uriParams>();
  const { user } = React.useContext(UserContext);
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [currentCards, setCurrentCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [submittedCards, setSubmittedCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [historyCards, setHistoryCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [coreMeasuresCards, setCoreMeasuresCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [submittedCoreMeasuresCards, setSubmittedCoreMeasuresCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [patientInfo, setPatientInfo] = React.useState<PatientData>(getNewPatientData());
  const location = useLocation<AssessmentLocationState>();
  const [coreMeasuresRedirect] = React.useState(location.state?.coreMeasuresRedirect ? location.state.coreMeasuresRedirect : 0);
  const [loadingCurrent, setLoadingCurrent] = React.useState<boolean>(true);
  const [loadingCoreMeasures, setLoadingCoreMeasures] = React.useState<boolean>(true);
  const [loadingHistory, setLoadingHistory] = React.useState<boolean>(true);
  const [isCoreMeasuresEnabled] = React.useState<boolean>(FeatureFlagManager.get<boolean>(FeatureFlagName.isCoreMeasuresEnabled));
  const [isAssessmentsSelectorWindowInTabletMode, setIsAssessmentsSelectorWindowInTabletMode] = React.useState<boolean>(false);
  const [showAssessmentsSelectorWindow, setShowAssessmentsSelectorWindow] = React.useState<boolean>(false);
  const [selectAssessmentsCardsAppliedWhenOptions, setSelectAssessmentsCardsAppliedWhenOptions] = React.useState<any[]>([]);
  const [selectAssessmentsCardsSelectedAppliedWhen, setSelectAssessmentsCardsSelectedAppliedWhen] = React.useState<SelectOption>();
  const [selectAssessmentsCards, setSelectAssessmentsCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [isSaveSelectedAssessmentsEnabled, setIsSaveSelectedAssessmentsEnabled] = React.useState<boolean>(false);
  const [showAssessmentsSendWindow, setShowAssessmentsSendWindow] = React.useState<boolean>(false);
  const [assessmentLinkEmail, setAssessmentLinkEmail] = React.useState<string>('');
  const [tabletModePin, setTabletModePin] = React.useState<string>('')
  const { SetActiveTabletModeId } = React.useContext(UserContext);
  const { SetActiveTabletModePatientId } = React.useContext(UserContext);
  const { SetActiveTabletModeAssessments } = React.useContext(UserContext);
  const [appliedWhenOptions, setAppliedWhenOptions] = React.useState<AssessmentAppliedWhen[]>([]);
  const [editAppliedWhenCard, setEditAppliedWhenCard] = React.useState<ProviderAssessmentCardProps>();
  const [showEditAppliedWhenWindow, setShowEditAppliedWhenWindow] = React.useState<boolean>(false);
  const [editAppliedWhenSelectedAppliedWhen, setEditAppliedWhenSelectedAppliedWhen] = React.useState<SelectOption>();
  const [editAppliedWhenErrorMessage, setEditAppliedWhenErrorMessage] = React.useState<string>('');

  const invalidEmail = (): boolean => !EmailInput.validEmail(assessmentLinkEmail);

  const isFacilityDirector = (): boolean => user?.role === Roles.FACILITY_DIRECTOR;
  const isFacilityDirectorOrProvider = (): boolean => isFacilityDirector() || user?.role === Roles.PROVIDER;

  const hasCoreMeasuresAccess = (): boolean => {
    if (user && isCoreMeasuresEnabled && (user.accessType === AccessType.ALL_ACCESS || user.accessType === AccessType.CORE_MEASURES)) {
      return true;
    }
    return false;
  };
  const hasOutcomesAccess = (): boolean => {
    // If core measures is not enabled, just return true.
    if (!isCoreMeasuresEnabled) {
      return true;
    }
    // Else, need to check access type
    return Boolean(user && (user.accessType === AccessType.ALL_ACCESS || user.accessType === AccessType.OUTCOMES));
  };

  React.useEffect(() => {
    const patientId = parseInt(patient, 10);
    PatientUsersProxy.getPatient(
      patientId,
      (response) => {
        if (response.data) {
          const loadedPatientData: PatientData = response.data[0];
          setPatientInfo(loadedPatientData);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );

    if (hasOutcomesAccess()) {
      FacilityUsersProxy.getPatientAssessments(patientId)
        .then((assessmentList) => {
          if (assessmentList && assessmentList.length) {
            const currentCardProps: ProviderAssessmentCardProps[] = getAssessmentCardProps(assessmentList, ProviderAssessmentTypes.TODO);
            const submittedCardProps: ProviderAssessmentCardProps[] = getAssessmentCardProps(assessmentList, ProviderAssessmentTypes.SUBMITTED);
            setCurrentCards(currentCardProps);
            setSubmittedCards(submittedCardProps);
          }
        })
        .catch((errorResponse) => {
          console.log(errorResponse);
        })
        .finally(() => setLoadingCurrent(false));
    } else {
      setLoadingCurrent(false);
    }

    if (hasCoreMeasuresAccess()) {
      FacilityUsersProxy.getPatientCoreMeasuresAssessments(patientId)
        .then((assessmentList) => {
          if (assessmentList && assessmentList.length) {
            const coreMeasuresCardProps: ProviderAssessmentCardProps[] = getAssessmentCardProps(assessmentList, ProviderAssessmentTypes.TODO, true);
            const submittedCoreMeasuresCardProps: ProviderAssessmentCardProps[] = getAssessmentCardProps(assessmentList, ProviderAssessmentTypes.SUBMITTED, true);
            setCoreMeasuresCards(coreMeasuresCardProps);
            setSubmittedCoreMeasuresCards(submittedCoreMeasuresCardProps);
          }
        })
        .catch((errorResponse) => {
          console.log(errorResponse);
        })
        .finally(() => setLoadingCoreMeasures(false));
    } else {
      setLoadingCoreMeasures(false);
    }

    FacilityUsersProxy.getPatientHistoryAssessments(patientId)
      .then((assessmentList) => {
        if (assessmentList && assessmentList.length) {
          const historyCardProps = getAssessmentCardProps(assessmentList, ProviderAssessmentTypes.HISTORY);
          setHistoryCards(historyCardProps);
        }
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
      })
      .finally(() => setLoadingHistory(false));

    if (isFacilityDirector()) {
      AssessmentProxy.getAppliedWhens()
        .then((aws) => {
          setAppliedWhenOptions(aws);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [patient]);

  const showSelectAssessmentsWindow = (isTabletMode: boolean): void => {
    const patientId = parseInt(patient, 10);
    FacilityUsersProxy.getPatientAssessmentsV2(patientId, 'Tablet')
      .then((r) => {
        const cards: ProviderAssessmentCardProps[] = getAssessmentCardProps(r.data!);
        const appliedWhens = cards
          .filter((card, index, array) => {
            const cardIndex = array.findIndex((c) => c.appliedWhenID === card.appliedWhenID);
            return cardIndex === index;
          })
          .map((card) => ({ appliedWhenId: card.appliedWhenID, appliedWhen: card.appliedWhen }));
        setSelectAssessmentsCardsAppliedWhenOptions(appliedWhens);
        setSelectAssessmentsCardsSelectedAppliedWhen(undefined);
        setSelectAssessmentsCards(cards);
        setIsAssessmentsSelectorWindowInTabletMode(isTabletMode);
        setTabletModePin('');
        setIsSaveSelectedAssessmentsEnabled(false);
        setShowAssessmentsSelectorWindow(true);
      });
  };

  const onTabSelect = (index: number, lastIndex: number): boolean => {
    if (index === lastIndex) {
      return false;
    }
    setTabIndex(index);
    switch (index) {
      case 0: // Current Assessments
        break;
      case 1: // History Assessments
        break;
      case 2: // Core Measures Assessments
        break;
      default:
        break;
    }

    return true;
  };

  const hasCurrentAssessments = (): boolean => currentCards.length > 0 || submittedCards.length > 0;
  const hasCoreMeasuresAssessments = (): boolean => coreMeasuresCards.length > 0 || submittedCoreMeasuresCards.length > 0;

  const getCoreMeasuresTabIndex = (): number => {
    if (hasCurrentAssessments() && hasOutcomesAccess()) {
      return 1;
    }
    return 0;
  };

  const assessmentCard = (content: ProviderAssessmentCardProps,
    forSelectDialog: boolean,
    onSelected: React.ChangeEventHandler<HTMLInputElement> | undefined,
    canEditAppliedWhen: boolean,
    onEditAppliedWhen: React.MouseEventHandler<SVGSVGElement> | undefined): JSX.Element => (
    <ProviderAssessmentCard
      id={content.id}
      title={content.title}
      appliedWhen={content.appliedWhen}
      appliedWhenID={content.appliedWhenID}
      instrumentTypeID={content.instrumentTypeID}
      instrumentType={content.instrumentType}
      careID={content.careID}
      patientID={content.patientID}
      assessmentNumber={content.assessmentNumber}
      assessmentStatus={content.assessmentStatus}
      completed={content.completed}
      ctas={forSelectDialog ? [] : content.ctas}
      assessmentLinkEmail={content.assessmentLinkEmail} // Last email this was sent to (null if never sent before)
      assessmentLinkSentDate={content.assessmentLinkSentDate} // Last date an email was sent (null if never sent before)
      availableForPatient={!isFacilityDirectorOrProvider() || forSelectDialog ? undefined : (content.availableForPatient)} // Whether an email can be sent to the patient
      isCoreMeasures={content.isCoreMeasures}
      sequenceNumber={content.sequenceNumber}
      loadAnswersFlag={content.isCoreMeasures && (content.assessmentStatus === 'Incomplete' || content.assessmentStatus === 'Completed')}
      dischargeDisp={content.dischargeDisp}
      submissionFrozen={content.submissionFrozen}
      allowSelection={forSelectDialog}
      onSelected={onSelected}
      awidEditable={content.awidEditable}
      canEditAppliedWhen={isFacilityDirector() && canEditAppliedWhen}
      onEditAppliedWhen={onEditAppliedWhen}
      documentID={content.documentID}
    />
  );

  const getAppliedWhenOptions = (card: ProviderAssessmentCardProps): AssessmentAppliedWhen[] => {
    const filtered = appliedWhenOptions
      .filter((awo) => {
        if (awo.appliedWhenID === card.appliedWhenID) {
          return false;
        }
        if (awo.appliedWhenID === 1) { // Admission
          const diffDays = moment.duration(moment(card.completed).diff(moment(card.dateAdmitted))).asDays();
          return diffDays >= -3 && diffDays <= 3;
        }
        if (awo.appliedWhenID === 8) { // During
          const admissionDiff = moment.duration(moment(card.completed).diff(moment(card.dateAdmitted))).asDays();
          const dischargeMoment = moment(card.dateDischarged);
          const beforeDischarge = dischargeMoment.year() < 1900 ? true : moment(card.completed).isBefore(dischargeMoment);
          return admissionDiff >= 3 && beforeDischarge;
        }
        if (awo.appliedWhenID === 2) { // Discharge
          const diffDays = moment.duration(moment(card.dateDischarged).diff(moment(card.completed))).asDays();
          return diffDays >= -2 && diffDays <= 2;
        }
        return true;
      });
    return filtered;
  }

  const currentAssessments = (): JSX.Element => {
    const hasCurrentCards = currentCards && currentCards.length > 0;
    const hasSubmittedCards = submittedCards && submittedCards.length > 0;
    return (
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="current-assessments-panel">
        {hasCurrentCards && (
          <>
            <TertiaryHeader text="To-Do" fontColor={FontColors.DARK} marginBottomPx={16} />
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} wrap extraClasses="assessment-card-container">
              {currentCards.map((card) => assessmentCard(card, false, undefined, false, undefined))}
            </FlexContainer>
          </>
        )}
        {hasSubmittedCards && (
          <>
            <hr />
            <TertiaryHeader text="Submitted" fontColor={FontColors.DARK} marginBottomPx={16} />
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} wrap extraClasses="assessment-card-container">
              {submittedCards.map((card) => assessmentCard(card,
                false,
                undefined,
                getAppliedWhenOptions(card).length > 0,
                () => {
                  setEditAppliedWhenCard(card);
                  setEditAppliedWhenSelectedAppliedWhen(undefined);
                  setEditAppliedWhenErrorMessage('');
                  setShowEditAppliedWhenWindow(true);
                }))}
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    );
  };

  const coreMeasuresAssessments = (): JSX.Element => {
    const hasCurrCoreMeasuresCards = coreMeasuresCards && coreMeasuresCards.length > 0;
    const hasSubmittedCoreMeasuresCards = submittedCoreMeasuresCards && submittedCoreMeasuresCards.length > 0;
    return (
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="current-assessments-panel">
        {hasCurrCoreMeasuresCards && (
          <>
            <TertiaryHeader text="To-Do" fontColor={FontColors.DARK} marginBottomPx={16} />
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} wrap extraClasses="assessment-card-container">
              {coreMeasuresCards.map((card) => assessmentCard(card, false, undefined, false, undefined))}
            </FlexContainer>
          </>
        )}
        {hasSubmittedCoreMeasuresCards && (
          <>
            <hr />
            <TertiaryHeader text="Submitted" fontColor={FontColors.DARK} marginBottomPx={16} />
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} wrap extraClasses="assessment-card-container">
              {submittedCoreMeasuresCards.map((card) => assessmentCard(card, false, undefined, false, undefined))}
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    );
  };

  const getHistorySections = (): any => {
    // Seperate by each stay (dateAdmitted - dateDischarged)
    const sectionContainers: AssessmentCardSectionContainer[] = [];
    let stays: string[] = [];

    // Sort by descending completed date
    const sortedCards = historyCards.sort((a, b) => {
      const firstDate = new Date(b.completed ? b.completed : '');
      const lastDate = new Date(a.completed ? a.completed : '');
      if (firstDate < lastDate) return -1;
      if (firstDate > lastDate) return 1;
      return 0;
    });

    // Get dates for sections (dateAdmintted - dateDischarged)
    sortedCards.forEach((val) => {
      const admitted = val.dateAdmitted ? val.dateAdmitted : '';
      if (admitted && stays.indexOf(admitted) < 0) {
        stays.push(admitted);
        sectionContainers.push({ title: '', entries: [] });
      }
    });
    stays = stays.sort();

    const getSectionTitle = (section: ProviderAssessmentCardProps): string => {
      const admitted = new MHODateTime(section.dateAdmitted ? section.dateAdmitted : '');
      const discharged = new MHODateTime(section.dateDischarged ? section.dateDischarged : '');
      return `${admitted.getFormattedCalendarDate()} - ${discharged.getFormattedCalendarDate()}`;
    };

    // Initialize card properties
    sortedCards.forEach((card) => {
      if (card.dateAdmitted) {
        const yearIndex = stays.indexOf(card.dateAdmitted);
        const section = sectionContainers[yearIndex];
        section.title = getSectionTitle(card);
        section.entries.push(card as ProviderAssessmentCardProps);
        section.entries = section.entries.sort((a: any, b: any) => (a.completed > b.completed ? 1 : -1));
      }
    });

    // const sections = [].concat([], ...sectionContainers);
    const sections: AssessmentCardSectionContainer[] = Array.from(sectionContainers);
    const filteredSections = sections.reverse().filter((section: AssessmentCardSectionContainer) => section.entries.length);

    return filteredSections.map((section: AssessmentCardSectionContainer) => (
      <CollapsibleSection bottomDivider banner={<TertiaryHeader text={section.title} fontColor={FontColors.PRIMARY} />}>
        {section.entries.filter((card: ProviderAssessmentCardProps) => !card.isCoreMeasures).length > 0 && (
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
              <TertiaryHeader text="Assessments" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
              <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
                {section.entries.filter((card: ProviderAssessmentCardProps) => !card.isCoreMeasures)
                  .map((card: ProviderAssessmentCardProps) => assessmentCard(card, false, undefined, false, undefined))}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        )}

        {section.entries.filter((card: ProviderAssessmentCardProps) => card.isCoreMeasures).length > 0 && hasCoreMeasuresAccess() && (
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
              <TertiaryHeader text="Core Measures" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
              <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
                {section.entries.filter((card: ProviderAssessmentCardProps) => card.isCoreMeasures)
                  .map((card: ProviderAssessmentCardProps) => assessmentCard(card, false, undefined, false, undefined))}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        )}
      </CollapsibleSection>
    ));
  };

  const historyAssessments = (): JSX.Element => (
    <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="history-assessments-panel">
      {getHistorySections()}
    </FlexContainer>
  );

  const saveTabletModeSession = (): void => {
    const selectedAssessments = selectAssessmentsCards.filter((c) => c.isSelected);
    AssessmentProxy.saveTabletModeSession({
      tabletMode: {
        patientID: parseInt(patient, 10),
        tabletModePIN: tabletModePin,
      },
      tabletModeAssessment: selectedAssessments.map<SaveTabletModeSessionArgsAssessment>((c) => ({
        instrumentTypeID: c.instrumentTypeID,
        assessmentNumber: parseInt(c.assessmentNumber, 10),
        appliedWhenID: c.appliedWhenID,
      })),
    }).then((r) => {
      SetActiveTabletModePatientId(patient);
      SetActiveTabletModeAssessments(JSON.stringify(selectedAssessments));
      SetActiveTabletModeId(r.data ? r.data.toString() : null);
    });
  };

  const sendAssessmentLinkEmail = (): void => {
    AssessmentProxy.sendAssessmentLink({
      assessmentLink: {
        accountID: user ? parseInt(user.accountId, 10) : -1,
        patientID: parseInt(patient, 10),
        assessmentLinkEmail,
      },
      assessmentLinkAssessment: selectAssessmentsCards.filter((c) => c.isSelected).map<SendAssessmentLinkArgsAssessment>((c) => ({
        instrumentTypeID: c.instrumentTypeID,
        assessmentNumber: parseInt(c.assessmentNumber, 10),
        appliedWhenID: c.appliedWhenID,
      })),
    }).then(() => {
      setAssessmentLinkEmail('');
      setShowAssessmentsSendWindow(false);
      window.location.reload();
    });
  };

  const saveAppliedWhen = (): void => {
    if (!editAppliedWhenCard || !editAppliedWhenSelectedAppliedWhen) {
      return;
    }
    AssessmentProxy.saveAppliedWhen(editAppliedWhenCard.documentID, parseInt(editAppliedWhenSelectedAppliedWhen.value, 10))
      .then((r) => {
        if (!r.success) {
          setEditAppliedWhenErrorMessage(r.message!);
        } else {
          setEditAppliedWhenErrorMessage('');
          setShowEditAppliedWhenWindow(false);
          window.location.reload();
        }
      });
  };

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Patient Details Page"
      bannerCard={(
        <PatientInfoCard
          patientID={patient}
          patientCareID={patientInfo.careID}
          patientFirstName={patientInfo.patientFirstName}
          patientLastName={patientInfo.patientLastName}
          patientDOB={patientInfo.dateOfBirth}
          patientAccountNumber={patientInfo.patientNumber}
          patientMedicalRecordNumber={patientInfo.medicalRecordNumber}
          patientProgram={patientInfo.programName}
          patientAdmissionDate={patientInfo.dateAdmitted}
          patientDischargeDate={patientInfo.dateDischarged}
          patientRegistrationPIN={patientInfo.registrationPIN?.toString() ?? ''}
          showRegisterButton
          disableRegisterButton={isCoreMeasuresEnabled && user?.accessType === AccessType.CORE_MEASURES}
        />
      )}
    >
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-details-page-content">
        <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="patient-details-page-content-assessments-header">
          <SecondaryHeader text="Assessments" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
          {isFacilityDirectorOrProvider() && hasCurrentAssessments() && hasOutcomesAccess() && tabIndex === 0 && (
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN}>
              <Button variant={ButtonVariant.PRIMARY} onClick={() => showSelectAssessmentsWindow(true)}>
                <InlineText text="Tablet Mode" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
              </Button>
              <Button variant={ButtonVariant.PRIMARY} onClick={() => showSelectAssessmentsWindow(false)}>
                <InlineText text="Email" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
              </Button>
            </FlexContainer>
          )}
        </FlexContainer>
        {loadingCurrent || loadingCoreMeasures || loadingHistory ? (
          <TertiaryHeader text="Loading..." fontColor={FontColors.PRIMARY} />
        ) : (
          <Tabs onSelect={onTabSelect} defaultIndex={coreMeasuresRedirect ? getCoreMeasuresTabIndex() : 0}>
            <TabList>
              {hasCurrentAssessments() && hasOutcomesAccess() && (
                <Tab>
                  <InlineText text="Current" fontColor={FontColors.DARK} fontSize={FontSizes.LARGE} />
                </Tab>
              )}
              {hasCoreMeasuresAssessments() && hasCoreMeasuresAccess() && (
                <Tab>
                  <InlineText text="Core Measures" fontColor={FontColors.DARK} fontSize={FontSizes.LARGE} />
                </Tab>
              )}
              <Tab>
                <InlineText text="History" fontColor={FontColors.DARK} fontSize={FontSizes.LARGE} />
              </Tab>
            </TabList>
            {hasCurrentAssessments() && hasOutcomesAccess() && <TabPanel>{currentAssessments()}</TabPanel>}
            {hasCoreMeasuresAssessments() && hasCoreMeasuresAccess() && <TabPanel>{coreMeasuresAssessments()}</TabPanel>}
            <TabPanel>{historyAssessments()}</TabPanel>
          </Tabs>
        )}
        {showAssessmentsSelectorWindow && (
          <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="popup-window-wrapper select-assessments-popup">
            <PopupWindow>
              <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="popup-window-content">
                <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.CENTER} extraClasses="popup-window-header">
                  <TertiaryHeader text="Select Assessments..." fontColor={FontColors.PRIMARY} />
                  <Button
                    variant={ButtonVariant.INVISIBLE}
                    onClick={(e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      setShowAssessmentsSelectorWindow(false);
                    }}
                  >
                    <X />
                  </Button>
                </FlexContainer>
                <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="current-assessments-panel">
                  {isAssessmentsSelectorWindowInTabletMode && (
                    <LabledTextInput
                      label="Tablet Mode PIN (6 digits)"
                      instructions={TABLET_MODE_PIN_INSTRUCTIONS}
                      name="exit-pin"
                      value={tabletModePin}
                      type="text"
                      onChange={(e) => setTabletModePin(e.target.value.replace(/[^0-9]/g, '').substring(0, TABLET_MODE_PIN_LENGTH))}
                      variant={TextInputVariant.PRIMARY}
                      maxTextLength={TABLET_MODE_PIN_LENGTH}
                      testid="exit-pin-input"
                      autoComplete="off"
                      inputMode="numeric"
                    />
                  )}
                  <LabledSingleSelect
                    styles={SingleStandardDropdownStyles}
                    label="Assessment Time Period"
                    options={selectAssessmentsCardsAppliedWhenOptions.map((v) => ({ value: v.appliedWhenId, label: v.appliedWhen }))}
                    defaultValue={null}
                    value={selectAssessmentsCardsSelectedAppliedWhen}
                    onSelection={(o) => {
                      setSelectAssessmentsCardsSelectedAppliedWhen(o);
                      selectAssessmentsCards.forEach((c) => { c.isSelected = false; });
                      setIsSaveSelectedAssessmentsEnabled(false);
                    }}
                  />
                  {selectAssessmentsCardsSelectedAppliedWhen && (
                    <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container">
                      {selectAssessmentsCards
                        .filter((c) => c.appliedWhenID === parseInt(selectAssessmentsCardsSelectedAppliedWhen.value, 10))
                        .map((card) => assessmentCard(card,
                          true,
                          (e) => {
                            card.isSelected = e.target.checked;
                            setIsSaveSelectedAssessmentsEnabled(selectAssessmentsCards.filter((c) => c.isSelected).length > 0);
                          },
                          false,
                          undefined))}
                    </FlexContainer>
                  )}
                </FlexContainer>
              </FlexContainer>
              <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="popup-window-content">
                <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="popup-window-buttons">
                  <Button variant={ButtonVariant.SECONDARY} onClick={() => setShowAssessmentsSelectorWindow(false)}>
                    <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    disabled={!isSaveSelectedAssessmentsEnabled || (isAssessmentsSelectorWindowInTabletMode ? tabletModePin.length !== TABLET_MODE_PIN_LENGTH : false)}
                    onClick={() => {
                      setShowAssessmentsSelectorWindow(false);
                      if (isAssessmentsSelectorWindowInTabletMode) {
                        saveTabletModeSession();
                      } else {
                        setShowAssessmentsSendWindow(true);
                      }
                    }}
                  >
                    <InlineText text="Save" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                </FlexContainer>
              </FlexContainer>
            </PopupWindow>
          </FlexContainer>
        )}
        {showAssessmentsSendWindow && (
          <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="popup-window-wrapper">
            <PopupWindow>
              <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="popup-window-content">
                <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.CENTER} extraClasses="popup-window-header">
                  <TertiaryHeader text="Send Assessment Link" fontColor={FontColors.PRIMARY} />
                  <Button
                    variant={ButtonVariant.INVISIBLE}
                    onClick={(e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      setShowAssessmentsSendWindow(false);
                    }}
                  >
                    <X />
                  </Button>
                </FlexContainer>
                <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="send-assessment-link-list">
                  <TertiaryHeader text="Assessments:" fontColor={FontColors.DARK_GRAY} />
                  {selectAssessmentsCards.filter((c) => c.isSelected).map((c) => (<TertiaryHeader text={`${c.instrumentType} (${c.assessmentNumber})`} fontColor={FontColors.PRIMARY} />))}
                </FlexContainer>
                <form className="send-assessment-link" onSubmit={sendAssessmentLinkEmail}>
                  <LabledTextInput
                    label="Email"
                    placeholder="Enter the email to send an assessment link to..."
                    name="email"
                    value={assessmentLinkEmail}
                    onChange={(e) => setAssessmentLinkEmail(e.target.value)}
                    type="email"
                    variant={TextInputVariant.PRIMARY}
                    autoFocus
                    testid="email-input"
                  />
                  <Button variant={ButtonVariant.PRIMARY} disabled={invalidEmail()} submit onClick={sendAssessmentLinkEmail} testid="submit-send-resend-button">
                    <InlineText text="Send" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                </form>
              </FlexContainer>
            </PopupWindow>
          </FlexContainer>
        )}
        {showEditAppliedWhenWindow && (
          <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="popup-window-wrapper">
            <PopupWindow>
              <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="popup-window-content">
                <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.CENTER} extraClasses="popup-window-header">
                  <TertiaryHeader text="Change Assessment Time Period" fontColor={FontColors.PRIMARY} />
                  <Button
                    variant={ButtonVariant.INVISIBLE}
                    onClick={(e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      setEditAppliedWhenCard(undefined);
                      setShowEditAppliedWhenWindow(false);
                    }}
                  >
                    <X />
                  </Button>
                </FlexContainer>
                <form className="save-applied-when" onSubmit={saveAppliedWhen}>
                  <LabledSingleSelect
                    styles={SingleStandardDropdownStyles}
                    label="Time Period"
                    options={getAppliedWhenOptions(editAppliedWhenCard!).map((c) => ({ value: c.appliedWhenID.toString(), label: c.appliedWhen }))}
                    defaultValue={null}
                    value={editAppliedWhenSelectedAppliedWhen}
                    onSelection={(o) => setEditAppliedWhenSelectedAppliedWhen(o)}
                  />
                  {editAppliedWhenErrorMessage !== '' && (
                    <Paragraph
                      text={editAppliedWhenErrorMessage}
                      fontColor={FontColors.HIGH_PRIORITY}
                      fontSize={FontSizes.REGULAR}
                      extraClasses="error-message"
                      bold
                    />
                  )}
                  <Button variant={ButtonVariant.PRIMARY} disabled={!editAppliedWhenSelectedAppliedWhen} submit onClick={saveAppliedWhen}>
                    <InlineText text="Save" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                </form>
              </FlexContainer>
            </PopupWindow>
          </FlexContainer>
        )}
      </FlexContainer>
    </PageLayout>
  );
};

export default PatientDetails;
