import ApiProxy from 'api/lib/ApiService';
import ApiResponse from 'api/lib/models/ApiResponse';
import ApiProxyV2 from 'api/lib/ApiService/v2';
import ApiResponseV2 from 'api/lib/models/ApiResponse/v2';
import { URLS } from 'constants/appUrls';
import { FacilityUserSearchResult, FacilityUserSearchCriteria } from 'interfaces/facilityUsers/facilityUserSearchResult';
import { CreateAccountRequest } from 'interfaces/facilityUsers/createAccountRequest';
import { CreateEntitlementRequest } from 'interfaces/facilityUsers/createEntitlementRequest';
import { PatientID, WorkListPatient } from 'interfaces/patients/patientData';
import { AssessmentData } from 'interfaces/assessments/assessmentData';
import { WorkListChartRow } from 'types/tableProps';

class FacilityUsersProxy {
  static Search(searchArgs: FacilityUserSearchCriteria, success: (e: ApiResponse<FacilityUserSearchResult[]>) => void, failure: (e: ApiResponse<FacilityUserSearchResult[]>) => void): void {
    const searchArray: string[] = [];

    const keys: (keyof FacilityUserSearchCriteria)[] = ['fname', 'lname', 'email', 'corpid', 'fid', 'rid', 'acstyid'];

    keys.forEach((key) => {
      const arg = searchArgs[key as keyof FacilityUserSearchCriteria];
      if (arg && arg !== '') {
        searchArray.push(`${key}=${arg}`);
      }
    });

    let searchString = '';

    for (let i = 0; i < searchArray.length; i += 1) {
      searchString += searchString === '' ? `?${searchArray[i]}` : `&${searchArray[i]}`;
    }

    const url = `${URLS.MHO_API}/ListAccount${searchString}`;

    ApiProxy.get<FacilityUserSearchResult>(
      url,
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  static CreateAccount(data: CreateAccountRequest, success: (e: ApiResponse<number>) => void, failure: (e: ApiResponse<number>) => void): void {
    const url = `${URLS.MHO_API}/Account`;
    const formattedRequest = [data];

    ApiProxy.post<number>(
      url,
      formattedRequest,
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  static CreateEntitlements(data: CreateEntitlementRequest[], success: (e: ApiResponse<number>) => void, failure: (e: ApiResponse<number>) => void): void {
    const url = `${URLS.MHO_API}/Entitlement`;

    ApiProxy.post<number>(
      url,
      data,
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  /**
   * Retrieve all assessments available to the patient, given the patient ID.
   *
   * @param patientID ID of the patient.
   * @return {Promise} a list of current assessments.
   */
  static getPatientAssessments(patientID: PatientID): Promise<AssessmentData[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/Dashboard/Provider/Current/${patientID}`;

      ApiProxy.get<AssessmentData[]>(
        url,
        (response: any) => {
          resolve(response.data);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  static getPatientAssessmentsV2(patientID: PatientID, mode: string): Promise<ApiResponseV2<AssessmentData[]>> {
    const url = `${URLS.MHO_API}/Dashboard/Provider/${mode}/${patientID}`;
    return ApiProxyV2.get<AssessmentData[]>(url);
  }

  /**
   * Retrieve all assessments available to the patient, given the patient ID.
   *
   * @param patientID ID of the patient.
   */
  static getPatientHistoryAssessments(patientID: PatientID): Promise<AssessmentData[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/Dashboard/Provider/History/${patientID}`;

      ApiProxy.get<AssessmentData[]>(
        url,
        (response: any) => {
          resolve(response.data);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  /**
   * Retrieve all core measures assessments available to the patient, given the patient ID.
   *
   * @param patientID ID of the patient.
   */
  static getPatientCoreMeasuresAssessments(patientID: PatientID): Promise<AssessmentData[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/Dashboard/Provider/CoreMeasures/${patientID}`;

      ApiProxy.get<AssessmentData[]>(
        url,
        (response: any) => {
          resolve(response.data);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  /**
   * Retrieve worklist for the logged in user on the provider dashboard
   */
  static async getProviderWorkList(facilityId?: string, groupTherapyId?: string, unitCodes?: string[]): Promise<WorkListPatient[]> {
    const url = `${URLS.MHO_API}/WkList`;
    if (!unitCodes || unitCodes.length === 0) {
      unitCodes = ['All'];
    }
    const params = {
      FacilityID: facilityId,
      GroupTherapyId: groupTherapyId,
      UnitCodes: unitCodes.map((uc) => ({ UnitCode: uc })),
    };

    const response = await ApiProxyV2.postWithResponse<any, WorkListPatient[]>(url, [params]);
    return response.data || [];
  }

  /**
   * Retrieve the work list chart data for a given facility.
   *
   * @param facilityID facility ID to retrieve the chart for.
   * @return {Promise} a list of chart data.
   */
  static getWorkListChart(facilityID: number): Promise<WorkListChartRow[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/PWLSpinChart/${facilityID}`;

      ApiProxy.get<WorkListChartRow[]>(
        url,
        (response: any) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }
}

export default FacilityUsersProxy;
