import * as React from 'react';

import './styles.scss';

import PageLayout from 'global_elements/Layouts/PageLayout';
import MentalHealthResourceCard from 'global_elements/Layouts/Cards/MentalHealthResourceCard';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { MentalHealthResourceCardProps } from 'types/cardProps';
import { FontColors } from 'global_elements/Text/variants';
import { useEffect } from 'react';
import UserProxy from '../../../api/user/userProxy';

const PatientResourcesPage = (): JSX.Element => {
  const [hotlineCards, setHotlineCards] = React.useState<MentalHealthResourceCardProps[]>([]);
  const [URLCards, setUrlCards] = React.useState<MentalHealthResourceCardProps[]>([]);

  useEffect(() => {
    UserProxy.getHelpFaq(3, 3)
      .then((resp) => {
        const items = resp.data || [];
        if (Array.isArray(items)) {
          const hotlines = items.filter((i: any) => i.hotlineResourceTypeID === 1 || i.hotlineResourceTypeID === 2 || i.hotlineResourceTypeID === 3);
          setHotlineCards(hotlines.map<MentalHealthResourceCardProps>((v) => ({
            description: v.helpFAQDesc,
            resourceLinkLabel: v.hotlineResourceLinkLabel,
            resourceLinkText: v.hotlineResourceLinkText,
            title: v.helpFAQLabel,
            type: v.hotlineResourceType,
          })));
          const urls = items.filter((i: any) => i.hotlineResourceTypeID === 4);
          setUrlCards(urls.map<MentalHealthResourceCardProps>((v) => ({
            description: v.helpFAQDesc,
            resourceLinkLabel: v.hotlineResourceLinkLabel,
            resourceLinkText: v.hotlineResourceLinkText,
            title: v.helpFAQLabel,
            type: v.hotlineResourceType,
          })));
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const urlCardGroup: JSX.Element = (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.START}
      justify={JustifyVariant.START}
      extraClasses="mhr-card-group"
    >
      <SecondaryHeader text="Resources" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.START}
        justify={JustifyVariant.SPACE_BETWEEN}
        extraClasses="mhr-card-group__resources"
        wrap
      >
        {URLCards.map((card) => (
          <MentalHealthResourceCard
            key={card.title}
            title={card.title}
            description={card.description}
            resourceLinkLabel={card.resourceLinkLabel}
            resourceLinkText={card.resourceLinkText}
            type={card.type}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );

  const hotlineCardGroup: JSX.Element = (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.START}
      justify={JustifyVariant.START}
      extraClasses="mhr-card-group"
    >
      <SecondaryHeader text="Hotlines" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.START}
        justify={JustifyVariant.SPACE_BETWEEN}
        extraClasses="mhr-card-group__resources"
        wrap
      >
        {hotlineCards.map((card) => (
          <MentalHealthResourceCard
            title={card.title}
            description={card.description}
            resourceLinkLabel={card.resourceLinkLabel}
            resourceLinkText={card.resourceLinkText}
            type={card.type}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Patient Resources Page"
      bannerCard={hotlineCardGroup}
    >
      {urlCardGroup}
    </PageLayout>
  );
};
export default PatientResourcesPage;
