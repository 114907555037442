import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AssessmentLinkData } from 'api/assessments/types'
import AssessmentProxy from 'api/assessments/assessmentProxy'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import InlineText from 'global_elements/Text/InlineText'
import { FontColors, FontSizes } from 'global_elements/Text/variants'

import AssessmentVerify from './AssessmentVerify'
import AssessmentForm from './AssessmentForm'

import './styles.scss'

interface UriParams {
  assessmentLinkHash: string
}

export function AssessmentPage(): JSX.Element {
  const { assessmentLinkHash } = useParams<UriParams>()
  const [assessmentLinkData, setAssessmentLinkData] = useState<AssessmentLinkData[] | null>()
  const [loading, setLoading] = useState<boolean>(true)
  const [expired, setExpired] = useState<boolean>(false)

  useEffect(() => {
    AssessmentProxy.verifyLinkHash(assessmentLinkHash).then(() => {
      setExpired(false)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
      setExpired(true)
    })
  }, [])

  const onVerified = (data: AssessmentLinkData[]): void => {
    setAssessmentLinkData(data)
  }

  if (loading || expired) {
    return (
      <PageLayout
        layout={PageLayoutVariant.PADDED}
        testText="Assessment Page"
        hideHeader
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="assessment-page-container"
        >
          {loading && (
            <InlineText
              text="Loading..."
              fontColor={FontColors.PRIMARY}
              fontSize={FontSizes.SUPER_EXTRA_LARGE}
            />
          )}
          {expired && (
            <InlineText
              text="Link has expired. Please contact your Care Provider."
              fontColor={FontColors.PRIMARY}
              fontSize={FontSizes.SUPER_EXTRA_LARGE}
            />
          )}
        </FlexContainer>
      </PageLayout>
    )
  }

  if (!assessmentLinkData) {
    return (
      <AssessmentVerify
        assessmentLinkHash={assessmentLinkHash}
        onVerified={onVerified}
      />
    )
  }

  return (
    <AssessmentForm
      assessmentLinkHash={assessmentLinkHash}
      assessmentLinkData={assessmentLinkData}
    />
  )
}
