import * as React from 'react';
import { ChangeEvent } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import MHODateTime from 'domain/dateTime/MHODateTime';
import * as DateInput from 'global_elements/Inputs/TextInput/lib/date';

import './styles.scss';

import DataListsProxy, { DataListType } from 'api/dataLists/dataListsProxy';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import Paragraph from 'global_elements/Text/Paragraph';
import Button from 'global_elements/Button';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabeledDateInput from 'global_elements/Inputs/LabeledDateInput';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';
import EthnicityLabel from 'global_elements/Text/EthnicityLabel';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { AllRoutes } from 'constants/routes';
import { PatientRegistrationState } from 'types/patientAccount';
import { SelectOption, ProgramSelectOption } from 'types/inputProps';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { PatientID } from 'interfaces/patients/patientData';
import { ListFacility } from 'interfaces/dataLists/listFacility';
import { ListProgram } from 'interfaces/dataLists/listFacilityProgram';
import { NetworkError } from 'api/lib/ApiService/errors';
import { NETWORK_ERROR_MESSAGE } from 'constants/errorMessages';
import { LabeledDatePickerInput } from 'global_elements/Inputs/LabeledDatePickerInput';

type URIParams = {
  facilityID: string;
};

const PatientRegistrationPage = (): JSX.Element => {
  const [facilityName, setFacilityName] = React.useState<string>('INVALID FACILITY ID');
  const [registrationError, setRegistrationError] = React.useState<string | null>(null);
  const [patientInfo, setPatientInfo] = React.useState<PatientRegistrationState>({
    firstName: '',
    lastName: '',
    accountEmail: '',
    accountNumber: '',
    medicalRecordNumber: '',
    DOB: '',
    admissionDate: '',
    program: { label: 'Select a Program', value: '' },
    programOptions: [],
    gender: { label: 'No Selection', value: '0' },
    race: { label: 'No Selection', value: '0' },
    hispan: '',
  });

  const [raceOptions, setRaceOptions] = React.useState<SelectOption[]>([]);
  const [genderOptions, setGenderOptions] = React.useState<SelectOption[]>([]);
  const [selectedAD, setSelectedAD] = React.useState<string>('');
  const [validateDOB, setValidateDOB] = React.useState<boolean>(false);
  const history = useHistory();
  const params = useParams<URIParams>();
  const facilityID = parseInt(params.facilityID, 10);

  const fetchOptions = (type: DataListType): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    DataListsProxy.getOptionList(
      type,
      (response) => {
        const options: SelectOption[] = [];

        if (response.data) {
          response.data.forEach((option) => {
            options.push({ label: option.lookupDesc, value: option.lookupID.toString() });
          });
        }

        resolve(options);
      },
      (errorResponse) => {
        console.log(errorResponse);
        reject(errorResponse);
      },
    );
  });

  React.useEffect(() => {
    fetchOptions(DataListType.Gender).then((options) => setGenderOptions(options));
    fetchOptions(DataListType.Race).then((options) => setRaceOptions(options));
  }, []);

  React.useEffect(() => {
    DataListsProxy.getUserFacilityList()
      .then((facilityList: ListFacility[]) => {
        const foundFacility = facilityList.find((facility) => facility.facilityID === facilityID);
        if (foundFacility) {
          setFacilityName(foundFacility.facilityName);
        }
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
      });
  }, [facilityID]);

  React.useEffect(() => {
    DataListsProxy.getPrograms(facilityID)
      .then((programs: ListProgram[]) => {
        const newProgramOptions: ProgramSelectOption[] = [{ label: 'Select a Program', value: '', maxAge: undefined, minAge: undefined }];
        for (let i = 0; i < programs.length; i += 1) {
          const option = programs[i];

          if (option.programID !== 0) {
            newProgramOptions.push({
              label: option.programName,
              value: option.programID.toString(),
              maxAge: option.maxAge,
              minAge: option.minAge,
            });
          }
        }

        setPatientInfo((prevState) => ({
          ...prevState,
          programOptions: newProgramOptions,
        }));
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
      });
  }, [facilityID]);

  const handleADChange = (date: Date): void => {
    setSelectedAD(date.toString());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name !== 'DOB' && e.target.name !== 'admissionDate') {
      const { name, value } = e.target;
      setPatientInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (DateInput.validDateInput(e)) {
      let maskedInput = DateInput.maskInputAsDate(e);
      if (e.target.value.length < patientInfo.DOB.length) {
        maskedInput = e.target.value;
      }
      if (maskedInput !== undefined) {
        setPatientInfo((prevState) => ({
          ...prevState,
          [e.target.name]: maskedInput,
        }));
      }
    }
  };

  const handleProgramChange = (option: SelectOption): void => {
    setPatientInfo((prevState) => ({
      ...prevState,
      program: option,
    }));
  };

  const handleGenderChange = (option: SelectOption): void => {
    setPatientInfo((prevState) => ({
      ...prevState,
      gender: option,
    }));
  };

  const handleRaceChange = (option: SelectOption): void => {
    setPatientInfo((prevState) => ({
      ...prevState,
      race: option,
    }));
  };

  const handleEthnicityChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setPatientInfo((prevState) => ({
      ...prevState,
      hispan: e.target.value,
    }));
  };

  const invalidDate = (field: 'DOB' | 'admissionDate'): string | undefined => {
    const minAge: number = patientInfo.program.minAge || 2;
    const maxAge: number = patientInfo.program.maxAge || 120;
    const dobMin: Date = new Date(Date.now());
    const dobMax: Date = new Date(Date.now());
    const admissionMinDate: Date = new Date(Date.now());
    const admissionMaxDate: Date = new Date(Date.now());

    dobMin.setFullYear(dobMin.getFullYear() - minAge);
    dobMax.setFullYear(dobMax.getFullYear() - maxAge);
    admissionMaxDate.setDate(admissionMinDate.getDate() + 3);
    admissionMinDate.setFullYear(admissionMaxDate.getFullYear() - 5);

    const fieldValue: string = patientInfo[field];

    const validYear = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if (fieldValue !== '' && !new RegExp(validYear).test(fieldValue)) {
      return 'Invalid Date (MM/DD/YYYY)';
    }

    if (fieldValue !== '' && !DateInput.validDate(fieldValue)) {
      return 'Invalid Date Entered';
    }

    if (fieldValue !== '' && field === 'DOB' && new Date(fieldValue) > dobMin) {
      return `Patient must be over ${minAge} years`;
    }

    if (fieldValue !== '' && field === 'DOB' && new Date(fieldValue) < dobMax) {
      return `Patient must be under ${maxAge} years`;
    }

    if (field === 'admissionDate' && (new Date(selectedAD) > admissionMaxDate || new Date(selectedAD) < admissionMinDate)) {
      return 'Invalid Admission Date';
    }

    return undefined;
  };

  const invalidRegistration = (): boolean => {
    if (
      patientInfo.program.value === ''
      || patientInfo.firstName.trim() === ''
      || patientInfo.lastName.trim() === ''
      || invalidDate('DOB')
      || invalidDate('admissionDate')
      || patientInfo.accountNumber.trim() === ''
      || patientInfo.medicalRecordNumber.trim() === ''
      || patientInfo.gender.value === '0'
      || patientInfo.race.value === '0'
      || patientInfo.hispan.trim() === ''
    ) {
      return true;
    }

    return false;
  };

  const cancelRegistration = (): void => {
    history.push(AllRoutes.PATIENT_ACCOUNTS);
  };

  const goToPatientAccountSetup = (patientID: PatientID): void => {
    if (patientID !== -1) {
      history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${patientID}`);
    }
  };

  const createPatientData = async (e: any): Promise<void> => {
    // Disable submit button while processing data
    const submitButton = e.target.closest('button');
    submitButton.disabled = true;

    const formattedDOB: MHODateTime = new MHODateTime(new Date(patientInfo.DOB));
    const formattedAdmissionDate: MHODateTime = new MHODateTime(new Date(selectedAD?.toString()));

    const race = parseInt(patientInfo.race.value, 10);
    const gender = parseInt(patientInfo.gender.value, 10);

    const data: any = {
      patientID: null,
      programID: parseInt(patientInfo.program.value, 10),
      programCode: null,
      patientFirstName: patientInfo.firstName,
      patientLastName: patientInfo.lastName,
      dateOfBirth: formattedDOB.getDateTimeForPost(),
      patientNumber: patientInfo.accountNumber,
      medicalRecordNumber: patientInfo.medicalRecordNumber,
      dateAdmitted: formattedAdmissionDate.getDateTimeForPost(),
      StatusID: 1,
      race: race === 0 ? undefined : race,
      gender: gender === 0 ? undefined : gender,
      hispan: patientInfo.hispan,
    };

    try {
      const response = await PatientUsersProxy.postPatient([data]);

      if (!response.success) {
        throw new Error(response.message);
      }

      if (response.data) {
        goToPatientAccountSetup(response.data);
        setRegistrationError(null);
      }
    } catch (error: any) {
      let { message } = error;

      if (error instanceof NetworkError) {
        message = NETWORK_ERROR_MESSAGE;
      }

      // Re-enable submit button to submit after adressing error
      submitButton.disabled = false;
      console.error(error);
      setRegistrationError(message ?? 'An unexpected error occurred.');
    }
  };

  // ***Patient registration / add patient
  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Patient Registration Page">
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-registration-content">
        <PrimaryHeader text="Add New Patient" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
        <form>
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-registration-content__first-row">
            <label>
              <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-registration-content__first-row__facility-header">
                <Paragraph text="Selected Facility" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} bold />
              </FlexContainer>
              <Paragraph text={`${facilityName} (${facilityID})`} fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} />
            </label>
            {patientInfo.programOptions.length === 0 && (
              <LabledSingleSelect
                styles={SingleStandardDropdownStyles}
                label="Program"
                options={[{ label: 'Loading...', value: '' }]}
                defaultValue={{ label: 'Loading...', value: '' }}
                value={{ label: 'Loading...', value: '' }}
                onSelection={handleProgramChange}
                testid="program-select"
              />
            )}
            {patientInfo.programOptions.length > 0 && (
              <LabledSingleSelect
                styles={SingleStandardDropdownStyles}
                label="Program"
                options={patientInfo.programOptions}
                defaultValue={patientInfo.program}
                value={patientInfo.program}
                onSelection={handleProgramChange}
                testid="program-select"
              />
            )}
          </FlexContainer>
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-registration-content__second-row">
            <LabledTextInput label="First Name" name="firstName" value={patientInfo.firstName} onChange={handleInputChange} type="text" variant={TextInputVariant.PRIMARY} testid="first-name-input" />
            <LabledTextInput label="Last Name" name="lastName" value={patientInfo.lastName} onChange={handleInputChange} type="text" variant={TextInputVariant.PRIMARY} testid="last-name-input" />
          </FlexContainer>
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-registration-content__fourth-row">
            <LabeledDateInput
              label="Date of Birth"
              name="DOB"
              value={patientInfo.DOB}
              onChange={handleInputChange}
              type="text"
              variant={TextInputVariant.PRIMARY}
              error={validateDOB || patientInfo.DOB?.length === 10 ? invalidDate('DOB') : undefined}
              testid="dob-input"
              onBlur={() => setValidateDOB(true)}
            />
            <LabeledDatePickerInput label="Admission Date" name="admissionDate" onChange={handleADChange} error={invalidDate('admissionDate')} testid="admission-date-input" />
          </FlexContainer>
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-registration-content__fifth-row">
            <LabledTextInput
              label="Account Number"
              name="accountNumber"
              value={patientInfo.accountNumber}
              onChange={handleInputChange}
              type="text"
              variant={TextInputVariant.PRIMARY}
              testid="account-number-input"
            />
            <LabledTextInput
              label="Medical Record Number"
              name="medicalRecordNumber"
              value={patientInfo.medicalRecordNumber}
              onChange={handleInputChange}
              type="text"
              variant={TextInputVariant.PRIMARY}
              testid="medical-record-number-input"
            />
          </FlexContainer>
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-registration-content__third-row">
            {genderOptions.length === 0 && (
              <LabledSingleSelect
                styles={SingleStandardDropdownStyles}
                label="Sex at Birth"
                options={[{ label: 'Loading...', value: '' }]}
                defaultValue={{ label: 'Loading...', value: '' }}
                value={{ label: 'Loading...', value: '' }}
                onSelection={handleGenderChange}
                testid="gender-select"
              />
            )}
            {genderOptions.length > 0 && (
              <LabledSingleSelect
                styles={SingleStandardDropdownStyles}
                label="Sex at Birth"
                options={genderOptions}
                defaultValue={patientInfo.gender}
                value={patientInfo.gender}
                onSelection={handleGenderChange}
                testid="gender-select"
              />
            )}
            {raceOptions.length === 0 && (
              <LabledSingleSelect
                styles={SingleStandardDropdownStyles}
                label="Race"
                options={[{ label: 'Loading...', value: '' }]}
                defaultValue={{ label: 'Loading...', value: '' }}
                value={{ label: 'Loading...', value: '' }}
                onSelection={handleRaceChange}
                testid="race-select"
              />
            )}
            {raceOptions.length > 0 && (
              <LabledSingleSelect
                styles={SingleStandardDropdownStyles}
                label="Race"
                options={raceOptions}
                defaultValue={patientInfo.race}
                value={patientInfo.race}
                onSelection={handleRaceChange}
                testid="race-select"
              />
            )}
          </FlexContainer>
          {/* ***added Hispanic Ethicity radio button */}
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START}>
            <label>
              <EthnicityLabel text="Hispanic Ethnicity" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} bold />
              <br />
              <EthnicityLabel text="Is the patient Hispanic, Latino, or Spanish ethnicity?" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} bold />
              <EthnicityLabel text="Yes" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} bold>
                <input type="radio" id="Hispanic-Ethnicity-rby" name="Hispanic-Ethnicity-list-type-rb" value="Y" onChange={handleEthnicityChange} />
              </EthnicityLabel>
              <EthnicityLabel text="No" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} bold testId="hispanic-ethnicity-no-label">
                <input type="radio" id="Hispanic-Ethnicity-rbn" name="Hispanic-Ethnicity-list-type-rb" value="N" onChange={handleEthnicityChange} title="Hispanic" />
              </EthnicityLabel>
            </label>
          </FlexContainer>
        </form>
        <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="button-container">
          <Button variant={ButtonVariant.SECONDARY} onClick={cancelRegistration}>
            <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
          <Button variant={ButtonVariant.PRIMARY} onClick={(e) => createPatientData(e)} disabled={invalidRegistration()} testid="submit-button">
            <InlineText text="Submit" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
        </FlexContainer>
        {registrationError && <InlineText text={registrationError} fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.LARGE} bold />}
      </FlexContainer>
    </PageLayout>
  );
};

export default PatientRegistrationPage;
