import { GroupTherapyAddAssessmentsArgs, GroupTherapyAssessmentsStatus, UriParams } from 'api/groupTherapy/types'
import './styles.scss'
import { useHistory, useParams } from 'react-router-dom'
import { AllRoutes } from 'constants/routes'
import { SelectOption } from 'types/inputProps'
import { useEffect, useState } from 'react'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PrimaryHeader from 'global_elements/Text/PrimaryHeader'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import Button from 'global_elements/Button'
import { ButtonVariant } from 'global_elements/Button/variants'
import InlineText from 'global_elements/Text/InlineText'
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect'
import LabeledMultiSelect from 'global_elements/Inputs/Dropdown/MultiSelect/LabeledMultiSelect'
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles'
import { MultiStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/MultiSelect/styles'
import GroupTherapyProxy from 'api/groupTherapy/GroupTherapyProxy'
import AssessmentProxy from '../../../api/assessments/assessmentProxy'

const GroupTherapyAddAssessmentsPage = (): JSX.Element => {
  const history = useHistory()
  const { groupId } = useParams<UriParams>()
  const [appliedWhenLoading, setAppliedWhenLoading] = useState<boolean>(true)
  const [appliedWhenOptions, setAppliedWhenOptions] = useState<SelectOption[]>([])
  const [assessmentsOptions, setAssessmentsOptions] = useState<SelectOption[]>([])
  const [selectedAppliedWhen, setSelectedAppliedWhen] = useState<SelectOption | undefined>(undefined)
  const [selectedAssessments, setSelectedAssessments] = useState<SelectOption[]>([])

  useEffect(() => {
    AssessmentProxy.getAppliedWhens().then((result) => {
      const options: SelectOption[] = result.map((appliedWhen) => ({ label: appliedWhen.appliedWhen, value: appliedWhen.appliedWhenID?.toString() }))
      setAppliedWhenOptions(options)

      if (options.length > 0) {
        setSelectedAppliedWhen(options[0])
      }

      setAppliedWhenLoading(false)
    })
  }, [])

  useEffect(() => {
    if (selectedAppliedWhen) {
      GroupTherapyProxy.getGroupTherapyAvailableAssessments(groupId, selectedAppliedWhen.value).then((result) => {
        const options: SelectOption[] = result.map((assessment) => ({ label: assessment.instrumentType, value: assessment.assessmentNumber.toString() }))
        setAssessmentsOptions(options)
      })
    }
  }, [selectedAppliedWhen])

  // Function to submit assessments and move to next screen.
  const submit = async (): Promise<void> => {
    const assessmentPayloads: GroupTherapyAddAssessmentsArgs[] = selectedAssessments.map((assessment) => ({
      GroupTherapyAssessmentID: null,
      GroupTherapyID: parseInt(groupId, 10),
      AssessmentNumber: parseInt(assessment.value, 10),
      AppliedWhenID: selectedAppliedWhen?.value ? parseInt(selectedAppliedWhen.value, 10) : 0,
      StatusID: GroupTherapyAssessmentsStatus.Active,
      CreatedBy: null,
      UpdatedBy: null,
    }))
    try {
      await GroupTherapyProxy.addGroupTherapyAssessments(assessmentPayloads)
      history.push(`${AllRoutes.GROUP_THERAPY_BASE}/${groupId}/participants`)
    } catch (error) {
      console.log(error)
    }
  }

  const inputValid = (): boolean => selectedAssessments.length > 0 && selectedAppliedWhen !== undefined

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Add Group Therapy Assessments Page"
      loadingText={appliedWhenLoading ? 'Loading...' : undefined}
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="group-therapy-add-assessments-container"
      >
        <PrimaryHeader text="Add Group Therapy Assessments" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
        <form className="group-therapy-form">
          {appliedWhenOptions.length === 0 && (
            <LabledSingleSelect
              label="Assessment Time Period"
              options={[]}
              onSelection={(option) => setSelectedAppliedWhen(option)}
              defaultValue={{ label: 'Loading...', value: '' }}
              styles={SingleStandardDropdownStyles}
              value={selectedAppliedWhen}
              testid="applied-when-select"
            />
          )}
          {appliedWhenOptions.length > 0 && (
            <LabledSingleSelect
              label="Assessment Time Period"
              options={appliedWhenOptions}
              onSelection={(option) => setSelectedAppliedWhen(option)}
              defaultValue={appliedWhenOptions[0]}
              styles={SingleStandardDropdownStyles}
              value={selectedAppliedWhen}
              testid="applied-when-select"
            />
          )}
          <LabeledMultiSelect
            label="Assessments (select one or more)"
            placeholder="Select assessments for this group therapy session"
            styles={MultiStandardDropdownStyles}
            options={assessmentsOptions}
            defaultValue={[]}
            value={undefined}
            onSelection={(option) => setSelectedAssessments(option)}
            testid="assessment-multiselect"
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={submit}
            disabled={!inputValid()}
            testid="submit-button"
          >
            <InlineText
              text="Save Assessments"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
        </form>
      </FlexContainer>
    </PageLayout>
  )
}

export default GroupTherapyAddAssessmentsPage
