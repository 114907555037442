/* eslint-disable react/no-this-in-sfc */
import React, { useContext, useEffect, useState, useRef } from 'react';
import * as Survey from 'survey-react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { AssessmentExtremeResponse } from 'domain/Forms/MHO/AssessmentExtremeResponse';
import AssessmentDefinitionProxy from 'api/assessments/assessmentDefinitionProxy';
import AssessmentProxy from 'api/assessments/assessmentProxy';
import { ExtremeResponseProxy } from 'api/assessments/extremeResponseProxy';
import { HiddenQuestionNames } from 'constants/MHO/hiddenQuestionNames';
import { EventResult, SurveyJSResult } from 'interfaces/assessments/QuestionResultFormat';
import AssessmentJSONDefinition from 'interfaces/assessments/AssessmentJSONDefinition';

import PageLayout from 'global_elements/Layouts/PageLayout';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import colors from 'global_elements/variables/colors.module.scss';
import PatientInfoCard from 'global_elements/Layouts/Cards/PatientInfoCard';
import { Roles } from 'constants/roles';
import { UserContext } from 'context/user';
import { SurveyJSQuestionTypes } from 'constants/SurveyJS/question_types';

import { AssessmentResultsWithExtremeDefinition } from 'domain/Forms/MHO/AssessmentResultsDefinition';
import { AdditionalSurveyAttrHandler } from './assessmentSurveyAttr';
import { getResponseRoleID } from './shared';
import { EmoticonController } from './questions/emoticon/emoticonController';
import { RatingEmoticons } from './questions/emoticon/emoticon-ratings';
import 'global_elements/Text/styles.scss';
import 'survey-react/survey.min.css';
import 'nouislider/distribute/nouislider.min.css';
import './questions/slider/styles.scss';
import '../AssessmentResult/styles.scss';
import { CommentController } from './questions/comment/commentController';
import { initSurveyJsSlider } from './questions/slider/slider';
import { AssessmentDraftKey, AssessmentDraftSessionStorage } from './AssessmentDraftSessionStorage';
import './assessment.scss';

const showdown = require('showdown');

const validFormDate = (date: string): boolean => moment(date, 'M/D/yyyy', true).isValid() || moment(date, 'M-D-yyyy', true).isValid();

initSurveyJsSlider(Survey);

// Load Survey from DB
// Pre fill out hidden questions and static form questions
// Convert on complete to MHO formatting
// Post up to server

const SetupSurveyTheme = (): void => {
  const defaultThemeColors = Survey.StylesManager.ThemeColors.default;
  defaultThemeColors['$main-color'] = colors.colorSecondary;
  defaultThemeColors['$header-color'] = colors.colorLightText; // survey header
  defaultThemeColors['$header-background-color'] = colors.colorSecondary;
  defaultThemeColors['$border-color'] = colors.colorStatus;
  Survey.StylesManager.applyTheme('default');
};
SetupSurveyTheme();

export interface PatientAssessmentData {
  programCode: number;
  patientNumber: string;
  treatmentTypeID: number;
  corporationID: number;
  populationTypeID: number;
  patientID: number;
  careID?: number;
  accountID: number;
}

export interface PatientBannerData {
  patientID: number | null;
  patientFirstName: string;
  patientLastName: string;
  dateOfBirth: string;
  patientNumber: string;
  medicalRecordNumber: string;
  programName: string;
  dateAdmitted: string;
  registrationPIN: number | null;
}

/**
 * @param assessmentNumber associated assessment number for the survey.
 * @param appliedWhenID the patient's 'Applied When' identifier to better retrieve the treatment info.
 * @param patient optional, patient to display the patient info.
 * @param onCompleteCallback a callback function of when the assessment is completed.
 * @param setIsDirtyCallback a callback function when assessment is dirtied.
 */
interface assessmentProp {
  assessmentNumber: number;
  appliedWhenID: number;
  patientAssessmentData: PatientAssessmentData;
  patientBannerData?: PatientBannerData;
  onCompleteCallback: (survey: Survey.Model, options: any) => Promise<void>;
  setIsDirtyCallback: any;
  onCancelCallback?: () => void;
  isPatient?: boolean;
  hideHeader?: boolean;
  extraClasses?: string;
  showExtremeMessages?: boolean;
  jsonDefinition?: AssessmentJSONDefinition;
  addCancelButton?: boolean;
  setIsCoreMeasures?: (isCoreMeasures: number) => void;
  sequenceNumber?: number;
  loadAnswersFlag?: boolean;
  dischargeDisp?: number | null;
}

/**
 * Create and retrieve html with the Assessment (SurveyJS), with the optional pre-populated data if it exists.
 *
 * @param props html component prop.
 * @returns HTML with the assessment survey.
 */
export function AssessmentSurvey(props: assessmentProp): JSX.Element {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [surveyModel, setSurveyModel] = React.useState<Survey.Model | null>(null);
  const [loadingSurvey, setLoadingSurvey] = React.useState(true);
  const [loadingAnswers, setLoadingAnswers] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [bannerHtml, setBannerHtml] = useState<any>(null);
  const [extremeResponses, setExtremeResponses] = useState<AssessmentExtremeResponse[]>([]);
  const [showInvalidMessage, setShowInvalidMessage] = useState<boolean>(false);
  const [jsonDefinition, setJsonDefinition] = useState<AssessmentJSONDefinition | null>(props.jsonDefinition ?? null);

  const isPatient = props.isPatient ?? (!user || user.role === Roles.PATIENT);
  const isPatientRef = useRef<boolean>(isPatient);
  isPatientRef.current = isPatient;

  const showExtremeMessages = props.showExtremeMessages ?? true;
  const shouldAddCancelButton = props.addCancelButton ?? true;

  const assessmentDraftKey: AssessmentDraftKey = {
    assessmentNumber: props.assessmentNumber,
    appliedWhenID: props.appliedWhenID,
    patientID: props.patientAssessmentData.patientID,
  };

  /**
   * Clear the internal custom surveyJS variable.
   */
  function clearExtremeMessage(): void {
    surveyModel?.setVariable('isExtremeMsgVisible', false);
    surveyModel?.setVariable('extremeMessageText', '');
  }

  /**
   * Cancel assessment page.  Goto the homepage/dashboard if the user is a patient,
   * and to the patient dashboard view, for non-patients.
   */
  function cancelAction(): void {
    AssessmentDraftSessionStorage.clear();

    if (props.onCancelCallback) {
      props.onCancelCallback();
      return;
    }

    if (isPatient) {
      history.push('/');
    } else {
      history.push(`/patient-accounts/${props.patientAssessmentData.patientID}`);
    }
  }

  /**
   * Add the cancel button to the assessment survey taker.
   */
  function addCancelButton(): void {
    let cancelIDContainer = document.getElementById('cancel-survey');
    if (cancelIDContainer === null) {
      cancelIDContainer = document.createElement('div');
      cancelIDContainer.setAttribute('id', 'cancel-survey');
      cancelIDContainer.className = 'sv-action';

      const actionContent = document.createElement('div');
      actionContent.className = 'sv-action__content';

      const cancelBtn = document.createElement('input');
      cancelBtn.setAttribute('id', 'survey-cancel-btn');
      cancelBtn.setAttribute('type', 'button');
      cancelBtn.setAttribute('value', 'Cancel');
      cancelBtn.className = 'sv_nav_btn sv_complete_btn';

      cancelBtn.onclick = cancelAction;

      actionContent.appendChild(cancelBtn);
      cancelIDContainer.appendChild(actionContent);

      const completeBtn = document.getElementById('sv-nav-complete');
      const buttonContainer = completeBtn?.parentNode;
      buttonContainer?.appendChild(cancelIDContainer);
    }
  }

  function addCustomQuestionStyle(options: any): void {
    const questionType = options.question.getType();

    switch (questionType) {
      case SurveyJSQuestionTypes.RATING: {
        if (options.question.getPropertyValue('useEmoticon')) {
          EmoticonController.updateEmoticonRatingStyle(options);
        }

        break;
      }
      case SurveyJSQuestionTypes.COMMENT: {
        CommentController.updateQuestionStyle(options);
        break;
      }
      default:
        break;
    }
  }

  /**
   * Async callback function to retrieve a flag whether to show the popup for the extreme messaging or not.
   *
   * @param this *hack* to type 'this' to any. 'this' is for the async call back to the surveyJS expression.
   * @returns dummy data. Async call returnResult with the actual value.
   */
  function showExtremeMessage(this: any): boolean {
    const isVisible = this.survey.getVariable('isExtremeMsgVisible');
    this.returnResult(isVisible);
    return false; // this is ignored.
  }

  /**
   * Async callback function to retrieve a flag whether to check if the current user is a patient or not.
   *
   * @param this *hack* to type 'this' to any. 'this' is for the async call back to the surveyJS expression.
   * @returns dummy data. Async call returnResult with the actual value.
   */
  function isNonPatientUser(this: any): boolean {
    this.returnResult(!isPatientRef.current);
    return !isPatientRef.current; // this is ignored.
  }

  /**
   * Async callback function to validate procedure codes on the PROC (18656) form.
   *
   * @param this *hack* to type 'this' to any. 'this' is for the async call back to the surveyJS expression.
   * @param procCodes The procedure code being validated. The validation function dictates that this must be an array,
   * but there will always just be one string value here.
   * @returns dummy data. Async call returnResult with the actual value.
   */
  function validateProcedureCode(this: any, procCodes: string[]): boolean {
    if (!procCodes[0]) {
      this.returnResult();
      return false; // This is ignored
    }

    const procCode = procCodes[0];

    AssessmentDefinitionProxy.validateProcedureCode(props.patientAssessmentData.patientID, procCode)
      .then((response) => {
        this.returnResult(response === 'Valid');
      })
      .catch((error: any) => {
        console.log(error);
        this.returnResult(false);
      });
    return false; // This is ignored
  }

  /**
   * Async callback function to validate diagnosis codes on the HBIPS Demographics (100030) form.
   *
   * @param this *hack* to type 'this' to any. 'this' is for the async call back to the surveyJS expression.
   * @param params The parameters from the form. The first value is the diagnosis code, and the second value is the discharge date.
   * @returns dummy data. Async call returnResult with the actual value.
   */
  function validateDiagnosisCode(this: any, params: string[]): boolean {
    if (params.length < 2 || !params[0] || !params[1]) {
      this.returnResult();
      return false; // This is ignored
    }

    const diagCode = params[0];
    const dischargeDate = params[1];

    AssessmentDefinitionProxy.validateDiagnosisCode(props.patientAssessmentData.patientID, diagCode, dischargeDate)
      .then((response) => {
        this.returnResult(response === 'Valid');
      })
      .catch((error: any) => {
        console.log(error);
        this.returnResult(false);
      });
    return false; // This is ignored
  }

  /**
   * SurveyJS Callback on when a question's answer is changed.
   *
   * @param editor survey JS instance.
   * @param option updated item and values.
   */
  const onValueChanged = (editor: any, option: any): void => {
    props.setIsDirtyCallback(true);

    if (surveyModel) {
      AssessmentDraftSessionStorage.save(assessmentDraftKey, surveyModel);
    }

    const variableID = option.question.getPropertyValue('variableID');
    const foundResponses = extremeResponses.filter((response) => response.variableID === variableID && response.dataValue === option.value);

    if (foundResponses.length && foundResponses[0].extremeResponsePopup === 1) {
      const response = foundResponses[0];
      const message = response?.extremeResponseMessage;
      surveyModel?.setVariable('isExtremeMsgVisible', true);

      const checkbox: HTMLInputElement = document.getElementById('emessage-dialog') as HTMLInputElement;
      if (checkbox) {
        checkbox.checked = true;
      }
      surveyModel?.setVariable('extremeMessageText', message);
    }
  };

  /**
   * Hook into the on progress change events to either update the text for the existing progress
   * bar and the floating bar.
   *
   * @param editor survey JS instance.
   * @param options surveyjs options for on progress event.
   */
  const onProgressText = (editor: any, options: any): void => {
    const percentage = Math.round((100 * options.answeredQuestionCount) / options.questionCount);
    options.text = `${percentage}% Complete`; // eslint-disable-line no-param-reassign
  };

  /**
   * Sets the selected Applied When display text for the 'Completed At:' header.
   *
   * @param survey created survey model.
   */
  const updateAppliedWhenResponseHeader = (survey: Survey.Model): void => {
    const appliedWhenHtml = document.getElementById('sv_applied_when');
    if (appliedWhenHtml) {
      const question = survey.getQuestionByName(HiddenQuestionNames.APPLIEDWHEN);
      const displayText = question.getDisplayValue(true);
      appliedWhenHtml.textContent = displayText || '<Unknown>'; // set an empty string if the question is undefined.
    }
  };

  const addProgressBarFloaterObserver = (): void => {
    const el = document.querySelector('.sv_progress');
    if (el) {
      const observer = new IntersectionObserver(
        ([e]) => {
          e.target.classList.toggle('is-pinned', e.intersectionRatio < 1);
        },
        { rootMargin: '-90px 0px 0px 0px', threshold: [1.0] },
      );
      observer.observe(el);
    }
  };

  /**
   * Insert "Completed At: <Applied When>" as a sub header text in the Survey JS.
   */
  const injectAppliedWhen = (): void => {
    let appliedWhenHtml = document.getElementById('sv_applied_when');
    if (appliedWhenHtml) {
      return; // already injected.
    }

    // Find the SurveyJS header to insert the 'Completed At: <applied when>'
    const headerHtml = document.getElementsByClassName('sv_header');
    let headerTextHtml = null;
    if (headerHtml.length) {
      headerTextHtml = headerHtml[0].getElementsByClassName('sv_header__text');
    }

    // Found the SurveyJS header and the header text.
    if (headerTextHtml && headerTextHtml.length) {
      // Create <span>Completed At: <span id="sv_applied_when">AppliedWhen</span></span>.
      const appliedWhenLabelHtml = document.createElement('span') as HTMLSpanElement;
      appliedWhenLabelHtml.textContent = 'Completed At: ';
      appliedWhenHtml = document.createElement('span') as HTMLSpanElement;
      appliedWhenHtml.setAttribute('id', 'sv_applied_when');

      // Append the new elements.
      appliedWhenLabelHtml.append(appliedWhenHtml);
      headerTextHtml[0].append(appliedWhenLabelHtml);
    }
  };

  /**
   * Setup initial survey properties.
   */
  const setupInitialSurveyProperties = (): void => {
    Survey.settings.useLocalTimeZone = true;
    const additionalProperties = AdditionalSurveyAttrHandler.getInstance().getAdditionalProperties();
    for (let index = 0; index < additionalProperties.length; index += 1) {
      Survey.Serializer.addProperty(additionalProperties[index].propertyName, additionalProperties[index].propertyInfo);
    }

    Survey.FunctionFactory.Instance.register('showExtremeMessage', showExtremeMessage, true);
    Survey.FunctionFactory.Instance.register('isNotPatient', isNonPatientUser, true);
    Survey.FunctionFactory.Instance.register('validateProcedureCode', validateProcedureCode, true);
    Survey.FunctionFactory.Instance.register('validateDiagnosisCode', validateDiagnosisCode, true);
  };

  useEffect(() => {
    setupInitialSurveyProperties();
  }, []);

  const afterRenderSurvey = (survey: Survey.Model): void => {
    // Setup extreme message text and controls once Survey Model is initialized.
    clearExtremeMessage();
    injectAppliedWhen();
    updateAppliedWhenResponseHeader(survey);
    addProgressBarFloaterObserver();
  };

  /**
   * Get previously submitted answers for the assessment. This applies only to Core Measures forms.
   * This is used for the edit feature, and for questions that may have already been answered on
   * a separate form.
   */
  const getSubmittedAnswers = (answers: SurveyJSResult, survey: Survey.Model): void => {
    AssessmentProxy.getAssessmentResultsWithExtreme(props.patientAssessmentData.patientID, props.assessmentNumber, props.appliedWhenID, props.sequenceNumber ? props.sequenceNumber : 1)
      .then((data) => {
        for (let i = 0; i < data.length; i += 1) {
          // If the answer is date, format it properly for SurveyJS
          if (validFormDate(data[i].dataValue)) {
            answers[data[i].variableName] = moment(data[i].dataValue).format('YYYY-MM-DD');
          } else {
            answers[data[i].variableName] = data[i].dataValue;
          }
        }

        // If a disabled variable is listed, disable it in the form
        if (data.length > 0 && data[0].variableDisabled) {
          survey.getQuestionByName(data[0].variableDisabled).readOnly = true;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        survey.data = answers;
        setLoadingAnswers(false);
      });
  };

  /**
   * This is a method to get answers specifically for the core measures events form (100031 or 60867). This assessment is unique because
   * it loads multiple forms into one assessment screen when editing.
   */
  const getSubmittedEvents = (answers: SurveyJSResult, survey: Survey.Model): void => {
    AssessmentProxy.getAssessmentResultsWithExtreme(props.patientAssessmentData.patientID, props.assessmentNumber, props.appliedWhenID, props.sequenceNumber ? props.sequenceNumber : 1)
      .then((data: AssessmentResultsWithExtremeDefinition[]) => {
        // Set abstractor's initials
        const abstractorsInitials = data.find((x) => x.variableName === 'HB23INT')?.dataValue;
        answers.HB23INT = abstractorsInitials ?? '';

        // Get number of forms
        const formCount = data.length / 86;

        answers = { ...answers, secRes: [] };

        for (let j = 1; j <= formCount; j += 1) {
          for (let i = 1; i <= 12; i += 1) {
            const eventTyp = data.find((x) => x.variableName === `EVENTTYP${i}` && x.sequenceNumber === j)?.dataValue;

            // If eventTyp is not null, that means there is an event.
            // In that case, get the rest of the variables.
            if (eventTyp) {
              const startDt = data.find((x) => x.variableName === `STARTDT${i}` && x.sequenceNumber === j)?.dataValue;
              const stopDt = data.find((x) => x.variableName === `STOPDT${i}` && x.sequenceNumber === j)?.dataValue;
              const startTim = data.find((x) => x.variableName === `STARTTIM${i}` && x.sequenceNumber === j)?.dataValue;
              const stopTim = data.find((x) => x.variableName === `STOPTIM${i}` && x.sequenceNumber === j)?.dataValue;
              const startUtd = data.find((x) => x.variableName === `STARTUTD${i}` && x.sequenceNumber === j)?.dataValue;
              const stopUtd = data.find((x) => x.variableName === `STOPUTD${i}` && x.sequenceNumber === j)?.dataValue;

              let formattedStartDt = startDt;
              let formattedStopDt = stopDt;

              // Format dates for SurveyJS
              if (startDt) {
                formattedStartDt = moment(startDt).format('YYYY-MM-DD');
              }

              if (stopDt) {
                formattedStopDt = moment(stopDt).format('YYYY-MM-DD');
              }

              const eventObj: EventResult = {
                EventTyp: eventTyp ?? '',
                StartDt: formattedStartDt ?? '',
                StopDt: formattedStopDt ?? '',
                StartTim: startTim ?? '',
                StopTim: stopTim ?? '',
                StartUTD: startUtd ? [startUtd] : [''],
                StopUTD: stopUtd ? [stopUtd] : [''],
              };

              (answers.secRes as EventResult[]).push(eventObj);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        survey.data = answers;
        setLoadingAnswers(false);
      });
  };

  const updateSurvey = (survey: Survey.Model): void => {
    survey.completeText = 'Submit';

    survey.onAfterRenderSurvey.add(() => {
      afterRenderSurvey(survey);
    });

    survey.onAfterRenderPage.add(() => {
      if (shouldAddCancelButton) {
        addCancelButton();
      }
    });

    survey.onAfterRenderQuestion.add((_surveyInstance: any, options: any) => {
      addCustomQuestionStyle(options);
    });

    const converter = new showdown.Converter();

    const doMarkdown = (_survey: any, options: any): void => {
      let str = converter.makeHtml(options.text);
      if (str.indexOf('<p>') === 0) {
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
      }
      options.html = str; // eslint-disable-line no-param-reassign
    };

    survey.onTextMarkdown.add(doMarkdown);

    setSurveyModel(survey);
  };

  useEffect(() => {
    if (props.jsonDefinition) {
      return;
    }

    AssessmentDefinitionProxy.getAssessmentJSONDefinition(props.assessmentNumber)
      .then((json: AssessmentJSONDefinition) => {
        setJsonDefinition(json);
        if (props.setIsCoreMeasures) {
          props.setIsCoreMeasures(json.coreMeasures); // Sets isCoreMeasures in parent component
        }
      })
      .catch((jsonDefinitionError: any) => {
        setErrorMessage(`Failed to load Assessment: ${jsonDefinitionError.message}`);
        setLoadingSurvey(false);
        setLoadingAnswers(false);
      });
  }, [props.assessmentNumber]);

  useEffect(() => {
    if (jsonDefinition) {
      let parsedJSON = {};
      try {
        parsedJSON = JSON.parse(jsonDefinition.assessmentEditorJSON);
      } catch (parseError: any) {
        setErrorMessage('Assessment not found.');
        setLoadingSurvey(false);
        setLoadingAnswers(false);
        return;
      }

      const survey = new Survey.Model(parsedJSON);
      survey.clearInvisibleValues = 'none'; // Retain the question values for hidden system question.

      const answers: SurveyJSResult = {};
      answers[HiddenQuestionNames.APPLIEDWHEN] = props.appliedWhenID;

      answers[HiddenQuestionNames.PROGRAMCODE] = props.patientAssessmentData.programCode;
      answers[HiddenQuestionNames.ACCOUNTNO] = props.patientAssessmentData.patientNumber;
      answers[HiddenQuestionNames.LEVELCARE] = props.patientAssessmentData.treatmentTypeID;
      answers[HiddenQuestionNames.CORPORATION] = props.patientAssessmentData.corporationID;
      answers[HiddenQuestionNames.POPULATION] = props.patientAssessmentData.populationTypeID;

      // Set admission date and discharge disposition as variables
      survey.setVariable('admissionDate', props.patientBannerData?.dateAdmitted ? props.patientBannerData.dateAdmitted.substring(0, 10) : '');
      survey.setVariable('dischargeDisp', props.dischargeDisp);

      // Only fill out the completed date for patients only.
      if (isPatient) {
        answers[HiddenQuestionNames.COMPLETED_DATE] = moment().format('YYYY-MM-DD');
      }

      AssessmentDraftSessionStorage.load(assessmentDraftKey).then((savedAnswers) => {
        if (savedAnswers) {
          Object.keys(savedAnswers).forEach((key) => {
            answers[key] = savedAnswers[key];
          });
        }

        // Get answers (for CM assessments only)
        if (props.loadAnswersFlag) {
          if (jsonDefinition.assessmentNumber === 100031 || jsonDefinition.assessmentNumber === 60867) {
            // Special method for forms 100031 and 60867
            getSubmittedEvents(answers, survey);
          } else {
            getSubmittedAnswers(answers, survey);
          }
        } else {
          survey.data = answers;
          setLoadingAnswers(false);
        }
        const question = survey.getQuestionByName(HiddenQuestionNames.APPLIEDWHEN);
        survey.clearIncorrectValues(); // clear unhandled answers. Primarily the props.appliedWhenID to check it matches one of the choices.

        if (!question) {
          setShowInvalidMessage(true);
          setLoadingSurvey(false);
          return;
        }

        updateSurvey(survey);
        setLoadingSurvey(false);
      });
    }
  }, [jsonDefinition, props.appliedWhenID]);

  useEffect(() => {
    if (isPatient && showExtremeMessages) {
      ExtremeResponseProxy.getAssessmentExtremeResponse(props.patientAssessmentData.patientID, props.assessmentNumber, props.appliedWhenID)
        .then((responses: AssessmentExtremeResponse[]) => {
          const userrole = user?.role ? user.role : undefined;
          const filteredResponses = responses.filter((response) => {
            if (userrole) {
              return response.extremeResponseRoleID === getResponseRoleID(userrole);
            }
            return false;
          });
          setExtremeResponses(filteredResponses);
        })
        .catch((errorResponse: any) => {
          console.log(errorResponse);
        });
    }
  }, [props.patientAssessmentData, props.assessmentNumber, isPatient, props.appliedWhenID, showExtremeMessages]);

  useEffect(() => {
    if (!bannerHtml && !isPatient && props.patientBannerData && props.patientAssessmentData.careID !== -1) {
      setBannerHtml(
        <PatientInfoCard
          patientID={String(props.patientBannerData.patientID)}
          patientFirstName={props.patientBannerData.patientFirstName}
          patientLastName={props.patientBannerData.patientLastName}
          patientDOB={props.patientBannerData.dateOfBirth}
          patientAccountNumber={props.patientBannerData.patientNumber}
          patientMedicalRecordNumber={props.patientBannerData.medicalRecordNumber}
          patientProgram={props.patientBannerData.programName}
          patientAdmissionDate={props.patientBannerData.dateAdmitted}
          patientRegistrationPIN={props.patientBannerData.registrationPIN?.toString() ?? ''}
          showRegisterButton={false}
        />,
      );
    }
  }, [props.patientBannerData, isPatient]);

  useEffect(() => {
    if (surveyModel) {
      afterRenderSurvey(surveyModel);
    }
  }, [surveyModel]);

  useEffect(() => {
    if (props.onCancelCallback) {
      const cancelButton = document.getElementById('survey-cancel-btn');

      if (cancelButton) {
        cancelButton.onclick = cancelAction;
      }
    }
  }, [props.onCancelCallback]);

  const onComplete = async (survey: Survey.Model, options: any): Promise<void> => {
    if (props.onCompleteCallback) {
      try {
        await props.onCompleteCallback(survey, options);
      } catch (error) {
        console.error(error);

        return;
      }
    }

    AssessmentDraftSessionStorage.clear();
  };

  /**
   * Correct SurveyJS bug that causes focused question to be hidden when validation fails.
   */
  const onValidatedErrorsOnCurrentPage = async (survey: Survey.Model, options: any): Promise<void> => {
    if (options.errors && options.errors.length > 0) {
      const correctedHeight = -75;
      setTimeout(() => window.scrollBy(0, correctedHeight), 100);
    }
  };

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Survey Page" bannerCard={bannerHtml} hideHeader={props.hideHeader ?? false} extraClasses={props.extraClasses}>
      {errorMessage && <InlineText text={errorMessage} fontColor={FontColors.PRIMARY} fontSize={FontSizes.SUPER_EXTRA_LARGE} />}
      {showInvalidMessage && (
        <span className={`text ${FontColors.PRIMARY} ${FontSizes.SUPER_EXTRA_LARGE}`}>
          Assessment invalid, please contact&nbsp;
          <a href="mailto:HelpReflections@horizonhealth.com">HelpReflections@horizonhealth.com</a>
        </span>
      )}
      {(loadingSurvey || loadingAnswers) && <InlineText text="Loading Survey, hang tight..." fontColor={FontColors.PRIMARY} fontSize={FontSizes.SUPER_EXTRA_LARGE} />}
      {!loadingSurvey && !loadingAnswers && !errorMessage && !showInvalidMessage && (
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="survey-container card-primary">
          <Survey.Survey
            model={surveyModel}
            onComplete={onComplete}
            onValueChanged={onValueChanged}
            onProgressText={onProgressText}
            onValidatedErrorsOnCurrentPage={onValidatedErrorsOnCurrentPage}
            css={{
              saveData: {
                saveAgainButton: 'sv_btn sv_btn__save',
                root: 'sv_save_msg',
                saving: 'sv_save_msg__saving',
                error: 'sv_save_msg__error',
                success: 'sv_save_msg__success',
              },
              dropdown: {
                selectWrapper: props.assessmentNumber === 100029 ? 'sv_select_wrapper metabolic-select-wrapper' : 'sv_select_wrapper',
              },
            }}
          />
        </FlexContainer>
      )}
      <RatingEmoticons />
    </PageLayout>
  );
}
